

import { defineComponent } from 'vue';
import { store } from '@/store';
import { M_MENU } from '@/store/mutations-types';
import Grid from '@/components/Grid.vue'
import api from '@/api/api';
import ModalCrud from '@/components/ModalCrud.vue';
import { columns } from '@/utils/columns';
import { formsValidations } from '@/utils/formsValidations';

export default defineComponent({
	name: 'Usuarios',
	components: {
		Grid,
		ModalCrud
	},
	data() {
		return {
			loadingGrid: false,
			idEdit: 0,
			textoModal: '',
			modalAberto: false,
			isLoading: false,
			valid: true,
			dsMenus: [],
			dsDepartamento: [],
			rulesPasswords: [],
			dataUser: {
				idUser: null,
				nomeCompleto: '',
				email: '',
				departamento: 0,
				password: '',
				passwordConfirma: '',
				status: true,
				supervisor: false,
				menus: []
			},
			tab: 0,
			users: [],
			openedMenus: [],
			gridItemsActions: [
				{
					label: 'Editar',
					action: 'edit',
					icon: 'mdi-pencil',
					color: 'primary',
					display: true,
					loading: false
				}
			]
		}
	},
	watch: {
		'dataUser.password': function (newVal, oldVal) {
			this.passwordRules();
			this.$nextTick(() => {
				this.$refs.form.validate();
			})
		},
		'dataUser.passwordConfirma': function (newVal, oldVal) {
			this.passwordRules();
			this.$nextTick(() => {
				this.$refs.form.validate()
			})
		}
	},
	computed: {
		canChangePassword: function () {
			return this.$store.state.infoUser.current.supervisor || this.dataUser.idUser === this.$store.state.infoUser.current.id || this.dataUser.idUser === null
		}
	},
	methods: {
		passwordRules: function () {
			this.rulesPasswords = [];
			if (this.dataUser.idUser > 0) {
				if (this.dataUser.password.length > 0) {
					const rule = v => (!!v && v) === this.dataUser.passwordConfirma || 'Senhas não conferem';
					this.rulesPasswords.push(rule);
					const ruleConfirma = v => (!!v && v) === this.dataUser.password || 'Senhas não conferem';
					this.rulesPasswords.push(ruleConfirma);
				}
			} else if (this.dataUser.idUser === null) {
				if (this.dataUser.password.length >= 0 || this.dataUser.passwordConfirma.length >= 0) {
					const rule = v => !!v || 'Senha precisa ser preenchida.'
					this.rulesPasswords.push(rule)
					const ruleCheck = v => (!!v && v) === this.dataUser.passwordConfirma || 'Senhas não conferem';
					this.rulesPasswords.push(ruleCheck);
					const ruleConfirma = v => (!!v && v) === this.dataUser.password || 'Senhas não conferem';
					this.rulesPasswords.push(ruleConfirma);

				}
			}
		},
		newUser: function () {
			this.openedMenus = [];
			this.tab = 0;
			this.dataUser = {
				idUser: null,
				nomeCompleto: '',
				email: '',
				departamento: 0,
				password: '',
				passwordConfirma: '',
				status: true,
				supervisor: false,
				menus: []
			};
			this.modalAberto = true;
			this.textoModal = 'Novo Usuário';
		},
		editUser: async function (item) {
			try {
				this.tab = 0;
				this.openedMenus = [];
				this.isLoading = true;
				this.modalAberto = true;
				this.textoModal = 'Editar Usuário';
				const response = await api.get(`/users/${item.ID}`);
				const backUserData = response.data[0];
				backUserData.status = backUserData.status === 'A';
				backUserData.supervisor = backUserData.supervisor === 'S';
				this.dataUser = backUserData;
			} catch (err) {
				this.$toast.error('Falha ao comunicar com o banco de dados!');
			} finally {
				this.isLoading = false;
			}
		},
		salvarUser: async function () {
			this.passwordRules();
			const valid = await this.$refs.form.validate();
			try {
				if (valid && this.dataUser.menus.length) {
					this.isLoading = true;
					this.dataUser.status === true ? this.dataUser.status = 'A' : this.dataUser.status = 'I';
					this.dataUser.supervisor === true ? this.dataUser.supervisor = 'S' : this.dataUser.supervisor = 'N';
					const [metodo, mensagem] = this.dataUser.idUser ? ['put', 'alterado'] : ['post', 'criado'];
					const operation = await api[metodo]('/users', this.dataUser);
					const response = await api.get('/users');
					this.users = response.data;
					this.$toast.success(`Usuário ${mensagem} com sucesso!`);
					this.modalAberto = false
				} else {
					if (!this.dataUser.menus) {
						this.tab = 1
					}
					this.$toast.warning('Formulário inválido! Verifique os dados.');
				}
			} catch (err) {
				this.$toast.error('Falha ao comunicar com o banco de dados!');
			} finally {
				this.isLoading = false;
			}
		},
		handleGridItemsActions: function (action: 'edit', item) {
			switch (action) {
				case 'edit':
					this.editUser(item);
					break;
				default:
					this.$toast.error('Opção de ação inválida!');
			};
		},
	},
	async mounted() {
		store.commit(M_MENU, 'Usuários');
		try {
			this.loadingGrid = true;
			const response = await api.get('/users/data');
			this.dsDepartamento = response.data.departamentos;
			this.users = response.data.users;
			this.dsMenus = response.data.menus;
		} catch (err) {
			this.$toast.error('Falha ao comunicar com o banco de dados!');
		} finally {
			this.loadingGrid = false;
		}
	},
	setup() {
		return {
			columns,
			formsValidations
		}
	}
})

