
import { defineComponent } from 'vue';
import { columns } from '@/utils/columns';
import Grid from '@/components/Grid.vue';

export default defineComponent({
	name: 'ModalVerDados',
	data() {
		return {
			tabs: null,
			columns: columns,
			actionsSteps: [
				{
					label: 'Editar',
					action: 'edit',
					icon: 'mdi-pencil',
					color: 'primary',
					display: true,
					loading: false
				},
				{
					label: 'Excluir',
					action: 'delete',
					icon: 'mdi-trash-can-outline',
					color: 'red',
					display: true,
					loading: false
				}
			],
			actionsClients: [
				{
					label: 'Excluir',
					action: 'delete',
					icon: 'mdi-trash-can-outline',
					color: 'red',
					display: true,
					loading: false
				}
			],
		}
	},
	components: {
		Grid
	},
	props: {
		title: {
			type: String,
			required: true
		},
		opened: {
			type: Boolean,
			required: true
		},
		loading: {
			type: Boolean,
			required: true
		},
		dataset: {
			type: Array,
			required: true
		},
		currentTab: {
			type: Number,
			required: true
		},
		observacaoProjeto: {
			type: String,
			required: true
		}
	},
	methods: {
		handleActions: function (action: 'new' | 'edit' | 'delete', item) {
			const emitAction = `${action}-${this.tabs === 0 ? 'step' : 'client'}`;
			this.$emit('actions', emitAction, item);
		},
	},
	watch: {
		'tabs': function (newValue, oldValue) {
			this.$emit('changeTab', newValue);
		},
	},
	mounted() {
		this.tabs = this.currentTab;
	},

})


