

import api from '@/api/api';
import SimpleTable from '@/components/SimpleTable.vue';
import { store } from '@/store';
import { M_MENU } from '@/store/mutations-types';
import { defineComponent } from 'vue';

export default defineComponent({
    name: "PainelOndeEstou",
    data() {
        return {
            dsTarefas: [],
            intervalo: null,
            horaAtual: null,
            columnsTable: [
                {
                    id: 1,
                    columnName: "Responsável"
                },
                {
                    id: 2,
                    columnName: "Onde Estou"
                },
                {
                    id: 3,
                    columnName: "Atividade"
                },
                {
                    id: 4,
                    columnName: "Início"
                },
                {
                    id: 5,
                    columnName: "Fim"
                }
            ]
        };
    },
    beforeDestroy() {
        clearInterval(this.intervalo);
    },
    async mounted() {
        if (this.dsTarefas.length === 0) {
            const response = await api.get("/dash/painelOndeEstou");
            this.dsTarefas = response.data;
        }
        store.commit(M_MENU, ``);
        this.intervalo = setInterval(() => {
            this.horaAtual = new Date().toLocaleTimeString();
        }, 1000);
    },
    components: { SimpleTable }
})

