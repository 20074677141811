import ExcelJS from 'exceljs';
/* eslint-disable */
type TXlsxHeader = {
    name:           string;
    label:          string;
    align?:         "left" | "right" | "center";
    headerClasses?: string;
    exportable?:    boolean;
    onlyExport?:    boolean;
    isObject?:      string;
    isCurrency?:    boolean;
}

type tExport = {
    title: string;
    columns: TXlsxHeader[];
    data: any[];
}

export class XlsxExport {
    curdate: string;
    workBook: ExcelJS.Workbook;
    title: string;
    columns: TXlsxHeader[];
    data: any[];

    constructor({ title, columns, data }: tExport) {
        if (!columns) throw new Error("Cant be instanciede")
        this.curdate = new Date().toLocaleDateString().replaceAll('/', '.');
        this.workBook = new ExcelJS.Workbook();
        this.title = title.concat(' - ', this.curdate).replace(/[*/\\[\]:?"<>|]/g, '');
        this.columns = columns.filter(col => col.name !== 'action');
        this.data = data;
    }
    async export() {
        try {
            const sheet = this.workBook.addWorksheet(this.title, {
                views: [
                    {
                        state: 'frozen',
                        ySplit: 1
                    }
                ]
            })

            const colsWithObjects: {
                name: string;
                objectValue: string
            }[] = []


            sheet.columns = this.columns.filter(x => x.exportable === undefined || x.exportable === true).map((col) => {
                if (col.isObject !== undefined) {
                    colsWithObjects.push({
                        name: col.name,
                        objectValue: col.isObject
                    })
                };
                return {
                    header: col.label,
                    key: col.name,
                    width: col.label.length < 12 ? 25 : col.label.length + 30,
                    
                }
            });

            sheet.getRow(1).font = {
                italic: true,
                color: {
                    argb: '#2196f3'
                },
                bold: true,
                size: 13
            };


            if (colsWithObjects.length) {
                let dataForUpdate = JSON.parse(JSON.stringify(this.data));
                for (let x = 0; x < dataForUpdate.length; x++) {
                    for (let y = 0; y < colsWithObjects.length; y++) {
                        dataForUpdate[x][colsWithObjects[y].name] = dataForUpdate[x][colsWithObjects[y].name][colsWithObjects[y].objectValue];
                    }
                };
                sheet.addRows(dataForUpdate);
                dataForUpdate = [];
            } else {
                sheet.addRows(this.data);
            };

            sheet.autoFilter = {
                from: {
                    row: 1,
                    column: 1
                },
                to: {
                    row: sheet.lastRow?.number!,
                    column: sheet.lastColumn?.number!
                }
            };

            for (let columnEach = 0; columnEach < this.columns.length; columnEach++){
                let currentColumn = columnEach + 1;
                this.columns[columnEach].isCurrency ? sheet.getColumn(currentColumn).numFmt = 'R$#,##0.00;[Red]-R$#,##0.00' : null;
                
            };
            await this.downloadWeb()
        } catch (error) {
            throw error
        }

    }

    async downloadWeb() {
        const buffer = await this.workBook.xlsx.writeBuffer();

        const blob = new Blob([buffer], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = this.title.concat('.xlsx');
        a.click();
        window.URL.revokeObjectURL(url);
    }
}
