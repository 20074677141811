

import api from '@/api/api';
import ModalCrud from '@/components/ModalCrud.vue';
import Grid from '@/components/Grid.vue';
import { store } from '@/store';
import { M_MENU } from '@/store/mutations-types';
import { defineComponent } from 'vue';
import selectOnShift from '@/utils/selectOnShift';

export default defineComponent({
	name: 'DashTrackAnalytic',
	components: {
		Grid,
		ModalCrud
	},
	data() {
		return {
			loadingGrid: false,
			exibeFiltro: false,
			search: {
				users: null,
				atividades: null,
				categorias: null,
				modulos: null,
				tags: null
			},
			dataToFilter: {
				option: '1',
				startDate: '',
				endDate: '',
				users: [],
				description: '',
				atividade: [],
				categoria: [],
				module: [],
				tag: []
			},
			dsUserToFilter: [],
			dsAtividades: [],
			dsCategorias: [],
			dsModule: [],
			dsTag: [],
			dsTempos: [],
			dataToGroup: [],
			isLoadingFilter: false,
			valid: true,
			dataIniRules: [
				v => !!v || 'Data inicial precisa ser preenchida.',
			],
			columns: [
				{
					text: 'ID',
					align: 'start',
					sortable: true,
					value: 'id',
				},
				{
					text: 'Responsável',
					sortable: true,
					value: 'user',
				},
				{
					text: 'Descrição',
					sortable: true,
					value: 'description',
				},
				{
					text: 'Atividade',
					sortable: true,
					value: 'activity',
				},
				{
					text: 'Módulo',
					sortable: true,
					value: 'module',
				},
				{
					text: 'Categoria',
					sortable: true,
					value: 'category',
				},
				{
					text: 'Tag',
					sortable: true,
					value: 'tag',
				},
				{
					text: 'Data',
					sortable: true,
					value: 'data',
				},
				{
					text: 'Hora Inicío',
					sortable: true,
					value: 'start',
				},
				{
					text: 'Hora Fim',
					sortable: true,
					value: 'end',
				},
				{
					text: 'Total',
					sortable: true,
					value: 'total',
				},
				{
					text: 'Origem',
					sortable: false,
					value: 'origem',
				}
			],
			totalGeral: {},
		}
	},
	computed: {
		dataFimRules() {
			const rules = []
			if (this.dataToFilter.endDate.length === 0) {
				const rule = v => !!v || 'Data fim precisa ser preenchida.';
				rules.push(rule);
			} else {
				const ruleCheck = (v) => v >= this.dataToFilter.startDate || 'Data final não pode ser menor que data inicial.';
				rules.push(ruleCheck);
			};
			return rules;
		},
	},
	methods: {
		selectOnShift,
		loadData: async function () {
			try {
				this.loadingGrid = true;
				if (this.dsTempos.length === 0) {
					this.dataToFilter.option = "1"
					const [response, dataToFilters] = await Promise.all([
						api.post('/dash/analytictoggl', this.dataToFilter),
						api.get('/datafiltertrack')
					])

					this.updateTracks(response.data);
					this.dsUserToFilter = dataToFilters.data.users;
					this.dsAtividades = dataToFilters.data.atividades;
					this.dsCategorias = dataToFilters.data.categoria;
					this.dsModule = dataToFilters.data.modulo;
					this.dsTag = dataToFilters.data.tag;
				};
			} catch (err) {
				this.$toast.error('Falha ao carregar os dados!');
				console.log(err)
			} finally {
				this.loadingGrid = false;
			};
		},
		changeData: async function () {
			try {
				const validation = this.dataToFilter.option === "3" ? this.$refs.formFilter.validate() : true;
				if (validation) {
					this.dsTempos = [];
					this.loadingGrid = true;
					this.exibeFiltro = false;
					const response = await api.post('/dash/analytictoggl', this.dataToFilter);
					this.updateTracks(response.data);
				} else {
					this.$toast.warning('Formulário inválido! Verifique os dados.');
				};
			} catch (err) {
				this.$toast.error('Falha ao carregar os dados!');
				console.log(err)
			} finally {
				this.loadingGrid = false;
			};
		},
		updateTracks: function (tracks) {
			this.dsTempos = tracks.resultAnalytic;
			this.dataToGroup = tracks.total.slice(1);
			this.totalGeral = tracks.total[0];
			let tempos = tracks.resultAnalytic.map((x) => ({
				...x,
				position: this.dataToGroup.findIndex((y) => {
					return x.user === y.NOMECOMPLETOUSUARIO
				})
			}));
			this.dsTempos = tempos;
		},
		toggleAll() {
			Object.keys(this.$refs).forEach((k) => {
				if (k.includes('btn-toggle-')) {
					this.$refs[k]?.$el.click();
				}
			})
		},
	},
	async mounted() {
		this.loadData();
		store.commit(M_MENU, `DashBoard Track - Analítico`);
	},
})

