

import { defineComponent } from 'vue';
import { store } from '@/store';
import { M_MENU } from '@/store/mutations-types';
import api from '@/api/api';

export default defineComponent({
    name: "HomeView",
    data() {
        return {
            dsCompromissos: [],
            dsPendencias: [],
            dsTime: [],
            totalizadorTempos: 0,
            minCol: 4
        }
    },

    async mounted() {
        if (this.dsTime.length === 0 || this.dsCompromissos.length === 0 || this.dsPendencias.length === 0) {
            try {
                const response = await api.get('/dash/Home');
                this.dsTime = response.data.tempos;
                this.totalizadorTempos = response.data.total;
                this.dsPendencias = response.data.pendencias;
                this.dsCompromissos = response.data.compromissos;
                if (!this.dsTime.length) {
                    this.minCol = 6;
                } else {
                    this.minCol = 4;
                }

            } catch (error) {
                console.log(error)

            }
        }
        store.commit(M_MENU, `Home`);
    }

})

