

type tModals = "Filter";

import api from '@/api/api';
import Grid from '@/components/Grid.vue';
import ModalCrud from '@/components/ModalCrud.vue';
import { store } from '@/store';
import { M_MENU } from '@/store/mutations-types';
import { columns } from '@/utils/columns';
import { defineComponent } from 'vue';

export default defineComponent({
	name: 'OndeEstou',
	components: {
		Grid,
		ModalCrud
	},
	data() {
		return {
			loadingGrid: false,
			dataOndeEstou: {
				id: 0,
				colaborador: 0,
				cliente: 0,
				atividade: 0,
				carro: 0,
				regiao: 0,
				dataIni: '',
				horaIni: '',
				dataFim: '',
				horaFim: '',
				status: false,
				motivoEstorno: ''
			},
			fieldValidate: [
				v => !!v || 'Esse campo precisa ser preenchido.',
			],
			deslocamento: '',
			envolveCliente: false,
			envolveCarro: false,
			envolveRegiao: false,
			textoModal: '',
			modalAberto: false,
			isLoading: false,
			valid: true,
			dsColaborador: [],
			atividade: '',
			dsAtividades: [],
			dsCliente: [],
			dsCarro: [],
			dsRegioes: [],
			estornar: false,
			jaEstornado: false,
			compromissos: [],
			columns: columns,
			modals: {
				modalFilter: {
					data: {
						form: {
							colaboradores: [],
							atividades: [],
							departamentos: [],
							dataInicial: '',
							dataFinal: ''
						},
						inputs: {
							dsColaborador: [],
							dsAtividades: [],
							dsDepartamentos: [],
						}
					},
					visible: false,
					isLoading: true
				}
			},
			gridItemsActions: [
				{
					label: 'Editar',
					action: 'edit',
					icon: 'mdi-pencil',
					color: 'primary',
					display: true,
					loading: false
				}
			]
		}
	},
	methods: {
		newCompromise: function () {
			this.dataOndeEstou = {
				id: 0,
				colaborador: 0,
				cliente: 0,
				carro: 0,
				regiao: 0,
				atividade: 0,
				dataIni: '',
				horaIni: '',
				dataFim: '',
				horaFim: '',
				status: false,
				motivoEstorno: ''
			},
				this.modalAberto = true;
			this.textoModal = 'Novo Compromisso';
			this.envolveCliente = false;
			this.envolveCarro = false;
			this.envolveRegiao = false;
			this.jaEstornado = false;
			this.estornar = false;
		},
		editCompromise: async function (item) {
			this.modalAberto = true;
			this.textoModal = 'Editar Compromisso'
			const response = await api.get(`/compromises/${item.ID}`)
			this.dataOndeEstou.id = response.data.ID
			this.dataOndeEstou.colaborador = response.data.CODCOLABORADOR
			this.dataOndeEstou.cliente = parseInt(response.data.CODCLIENTE)
			this.dataOndeEstou.carro = parseInt(response.data.CODCARRO)
			this.dataOndeEstou.atividade = parseInt(response.data.CODATIVIDADE)
			this.dataOndeEstou.regiao = parseInt(response.data.CODREGIAO)
			this.dataOndeEstou.dataIni = response.data.DATAINI
			this.dataOndeEstou.horaIni = response.data.HORAINI
			this.dataOndeEstou.dataFim = response.data.DATAFIM
			this.dataOndeEstou.horaFim = response.data.HORAFIM
			this.dataOndeEstou.status = response.data.CODIGO_STATUS === 'I' ? true : false
			this.estornar = response.data.CODIGO_STATUS === 'I' ? true : false
			this.jaEstornado = response.data.CODIGO_STATUS === 'I' ? true : false

			const atividade = this.dsAtividades.find((x) => x.CODTAREFAEXTERNO === parseInt(response.data.CODATIVIDADE))
			this.envolveCliente = atividade.CLIENTE === 'S' ? true : false
			this.envolveCarro = atividade.CARRO === 'S' ? true : false
			this.envolveRegiao = atividade.REGIAO === 'S' ? true : false
			this.motivoEstorno = response.data.MOTIVO
		},
		saveCompromise: async function () {
			try {
				if (this.$refs.form.validate()) {
					this.isLoading = true;
					const [metodo, mensagem] = this.dataOndeEstou.id ? ['put', 'alterado'] : ['post', 'criado'];
					this.dataOndeEstou.status = this.dataOndeEstou.status ? 'I' : 'A'
					const operation = await api[metodo]('/compromises', this.dataOndeEstou);
					const response = await api.get('/compromises');
					this.compromissos = response.data;
					this.$toast.success(`Compromisso ${mensagem} com sucesso!`);
					this.modalAberto = false;
				} else {
					this.$toast.warning('Formulário inválido! Verifique os dados.');
				}
			} catch (err) {
				this.$toast.error('Falha ao comunicar com o banco de dados!');
				console.log(err)
			} finally {
				this.isLoading = false
			}
		},
		checkAtividade: function () {
			const atividade = this.dsAtividades.find((x) => x.CODTAREFAEXTERNO === this.dataOndeEstou.atividade)
			this.envolveCliente = atividade.CLIENTE === 'S' ? true : false
			this.envolveCarro = atividade.CARRO === 'S' ? true : false
			this.envolveRegiao = atividade.REGIAO === 'S' ? true : false
		},
		closeModal: function () {
			this.modalAberto = false,
				this.textoModal = ''
		},
		changeDataCar: function () {
			const dadosDeslocamento = this.dsCarro.find((x) => x.CARRO === this.deslocamento);
			this.dataOndeEstou.deslocamento.id = dadosDeslocamento.CODIGOCARRO;
			this.dataOndeEstou.deslocamento.tipo = dadosDeslocamento.tipo;
		},
		async handleModals(modal: tModals) {
			try {
				this.loadingGrid = true;
				this.modals.modalFilter.isLoading = true;
				let response;
				switch (modal) {
					case 'Filter':
						this.modals.modalFilter.visible = true;
						response = await api.get('/compromises/data-filter');
						this.modals.modalFilter.data.inputs.dsAtividades = response.data.atividades;
						this.modals.modalFilter.data.inputs.dsColaborador = response.data.colaboradores;
						this.modals.modalFilter.data.inputs.dsDepartamentos = response.data.departamentos;
						break;
				}
			} catch (error) {
				this.$toast.error('Falha ao comunicar com o banco de dados!');
			} finally {
				this.loadingGrid = false;
				this.modals.modalFilter.isLoading = false;
			}
		},
		async handleActions(modal: tModals, action: 'confirm' | 'close') {
			try {
				let response;
				this.modals.modalFilter.isLoading = true;
				switch (modal) {
					case 'Filter':
						switch (action) {
							case 'confirm':
								response = await api.get('/compromises', {
									params: this.modals.modalFilter.data.form
								});
								this.compromissos = response.data;
								await this.handleActions('Filter', 'close');
								break;
							case 'close':
								this.modals.modalFilter.data.inputs = {
									dsColaborador: [],
									dsAtividades: [],
									dsDepartamentos: []
								}
								this.modals.modalFilter.visible = false;
								break;
						}
						break;
				}
			} catch (error) {
				this.$toast.error('Falha ao comunicar com o banco de dados!');
			} finally {
				this.modals.modalFilter.isLoading = false;
			}
		},
		handleGridItemsActions: function (action: 'edit', item) {
			switch (action) {
				case 'edit':
					this.editCompromise(item);
					break;
				default:
					this.$toast.error('Opção de ação inválida!');
			};
		},
	},
	computed: {
		dataFimRules() {
			const rules = []
			if (this.dataOndeEstou.dataIni.length === 0) {
				const rule = v => !!v || 'Data fim precisa ser preenchida.'
				rules.push(rule)
			} else {
				const ruleCheck = (v) => {
					if (v < this.dataOndeEstou.dataIni) {
						return 'Data final não pode ser menor que data inicial.'
					}
				}
				rules.push(ruleCheck)
			}
			return rules;
		},
		horaFimRules() {
			const rules = []
			if (this.dataOndeEstou.horaFim.length === 0) {
				const rule = v => !!v || 'Hora fim precisa ser preenchida.'
				rules.push(rule)
			} else {
				if (this.dataOndeEstou.dataIni === this.dataOndeEstou.dataFim) {
					const ruleCheck = (v) => {
						if (v < this.dataOndeEstou.horaIni) {
							return 'Hora final não pode ser menor que hora inicial.'
						}
					}
					rules.push(ruleCheck)
				}
			}
			return rules;
		}
	},
	async mounted() {
		try {
			this.loadingGrid = true;
			store.commit(M_MENU, 'Agenda');
			const response = await api.get('/datatocompromise');
			this.compromissos = response.data.compromissos;
			this.dsColaborador = response.data.colab;
			this.dsAtividades = response.data.atividades;
			this.dsCliente = response.data.clientes;
			this.dsCarro = response.data.carros;
			this.dsRegioes = response.data.regioes;
		} catch (error) {
			this.$toast.error('Falha ao comunicar com o banco de dados!');
		} finally {
			this.loadingGrid = false;
		}


	},
})

