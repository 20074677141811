import { StateGlobal } from "@/store";
import { CURRENT_USER } from "@/store/actions-types";
import { M_CURRENT_USER } from "@/store/mutations-types";
import { IUser } from "@/types/IAuth";
import { Module } from "vuex";

export interface StateUser {
    current: IUser;
}


export const infoUser: Module<StateUser, StateGlobal> = {
    mutations: {
        [M_CURRENT_USER](state, user: IUser){             
            state.current = user                                            
        }
    },
    actions: {
        [CURRENT_USER]({commit}, user: IUser){             
            commit(M_CURRENT_USER, user);                       
        }
    }
}


