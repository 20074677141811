

import api from '@/api/api';
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'PendenciasView',
    data() {
        return {
            autoCompleteFilterSetor: {
                selectedSetor: null,
                search: '',
                items: []
            },
            dataset: [],
            canSeeFilterSetor: false,
            interval: null
        }
    },
    methods: {
        renderIcon(priority: 'Urgente' | 'Alta' | 'Normal' | 'Baixa') {
            switch (priority) {
                case 'Urgente':
                    return 'mdi-fire'
                case 'Alta':
                    return 'mdi-arrow-up-bold'
                case 'Normal':
                    return 'mdi-equal'
                case 'Baixa':
                    return 'mdi-arrow-down-bold'
                default:
                    return 'mdi-alert-box'
            }
        },
        showOrHideFilters() {
            this.canSeeFilterSetor = !this.canSeeFilterSetor;
            this.$store.state.canSeeHeader = !this.$store.state.canSeeHeader;
        },
        async reloadData() {
            try {
                const { data } = await api.get('/dash/pendenciasPed');
                let orderedData = data.map((x) => {
                    return {
                        DESCRICAOSETOR: x.DESCRICAOSETOR,
                        tasks: x.tasks.map((y) => {
                            return {
                                user: y.user,
                                tasks: y.tasks.map((z) => {
                                    return {
                                        tarefa: z.tarefa,
                                        prioridade: z.prioridade,
                                        order: z.order
                                    }
                                }).sort((a, b) => Number(a.order) - Number(b.order)).slice(0, 10)
                            }
                        }).sort((a, b) => a.user - b.user)
                    }
                });
                this.dataset = orderedData;
                this.autoCompleteFilterSetor.items = this.dataset.map(x => x.DESCRICAOSETOR).sort();
            } catch (error) {
                this.$toast.error('Falha ao atualizar os dados!');
            }
        }
    },
    computed: {
        filterBySetor() {
            return this.dataset.filter(x => x.DESCRICAOSETOR === this.autoCompleteFilterSetor.selectedSetor);
        }
    },
    async mounted() {
        try {
            this.$store.state.canSeeHeader = false;
            this.$store.state.loading = true;
            const { data } = await api.get('/dash/pendenciasPed');
            let orderedData = data.map((x) => {
                return {
                    DESCRICAOSETOR: x.DESCRICAOSETOR,
                    tasks: x.tasks.map((y) => {
                        return {
                            user: y.user,
                            tasks: y.tasks.map((z) => {
                                return {
                                    tarefa: z.tarefa,
                                    prioridade: z.prioridade,
                                    order: z.order
                                }
                            }).sort((a, b) => Number(a.order) - Number(b.order)).slice(0, 10)
                        }
                    }).sort((a, b) => a.user - b.user)
                }
            });
            this.dataset = orderedData;
            this.autoCompleteFilterSetor.items = this.dataset.map(x => x.DESCRICAOSETOR).sort();
            this.autoCompleteFilterSetor.selectedSetor = this.dataset.filter(x => x.DESCRICAOSETOR === this.$store.state.infoUser.current.setor).length ? this.$store.state.infoUser.current.setor : this.autoCompleteFilterSetor.items[0];
            this.interval = setInterval(async () => {
                await this.reloadData()
            }, 15000);
        } catch (error) {
            this.$toast.error('Falha ao carregar os dados!');
        } finally {
            this.$store.state.loading = false;

        }
    },
    unmounted() {
        this.$store.state.canSeeHeader = true;
        clearInterval(this.interval);
    },
})
