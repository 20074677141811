
import { defineComponent } from 'vue';
import api from '@/api/api';
import { store } from '@/store';
import { M_MENU } from '@/store/mutations-types';
import { columns } from '@/utils/columns';
import Grid from '@/components/Grid.vue';
import ModalCrud from '@/components/ModalCrud.vue';
import selectOnShift from '@/utils/selectOnShift';
import ModalVerDados from './ModalVerDados.vue';

type TActions = 'edit-track' | 'new-step' | 'edit-step' | 'delete-step' | 'new-client' | 'edit-client' | 'delete-client' | 'ver-dados';

export default defineComponent({
	name: "TrackProjetos",
	data() {
		return {
			modalFormProjeto: {
				title: "",
				isOpen: false,
				isLoading: false,
			},
			dataTrackProjetos: {
				id: 0,
				idTipoProjeto: 0,
				descricao: "",
				idStatusRedmine: 0,
				prioridade: 0,
				progresso: 0,
				idTarefaRedmine: null,
				observacao: "",
				dataIni: "",
				dataFim: "",
				idStatus: true
			},
			modalFiltros: {
				isOpen: false,
				isLoading: false,
			},
			dadosFiltros: {
				statusRedmine: [],
				prioridadeTarefas: [],
				tipoProjeto: [],
			},
			modalFormEtapas: {
				title: "",
				isOpen: false,
				isLoading: false,
			},
			dataFormEtapa: {
				id: 0,
				idAcompanhamentoProjeto: 0,
				descricao: "",
				progresso: 0,
				idResponsavel: 0,
				observacao: "",
				idStatus: true,
				dataInicial: "",
				dataFinal: "",
			},
			modalFormClientes: {
				title: "",
				isOpen: false,
				isLoading: false,
			},
			dataFormClientes: {
				id: 0,
				idAcompanhamentoProjeto: 0,
				idClients: [],
				observacao: ""
			},
			modalVerDados: {
				title: "",
				isOpen: false,
				isLoading: false,
				idAcompanhamentoProjeto: 0,
				observacaoProjeto: "",
				dataset: [],
				tabs: 0
			},
			trackProjetos: [],
			dsTipoProjeto: [],
			dsStatusTarefas: [],
			dsPrioridadeToFilter: [
				{
					id: 0,
					descricao: '0'
				},
				{
					id: 1,
					descricao: '1'
				},
				{
					id: 2,
					descricao: '2'
				},
				{
					id: 3,
					descricao: '3'
				},
				{
					id: 4,
					descricao: '4'
				},
				{
					id: 5,
					descricao: '5'
				}
			],
			dsPrioridade: [
				0,
				1,
				2,
				3,
				4,
				5
			],
			dsResponsavelEtapa: [],
			dsClientesSolicitantes: [],
			fieldValidatePercentual: [
				v => !(v < 0 || v > 100) || "Não pode ser menor que 0% nem maior que 100%",
			],
			fieldValidatePrioridade: [
				v => !(v === null) || "Esse campo precisa ser preenchido.",
			],
			fieldValidate: [
				v => !!v || "Esse campo precisa ser preenchido.",
			],
			fieldValidateClientes: [
				v => !!v.length || "Esse campo precisa ser preenchido.",
			],
			loadingGrid: false,
			columns: columns,
			gridItemsActions: [
				{
					label: 'Editar',
					action: 'edit-track',
					icon: 'mdi-pencil',
					color: 'primary',
					display: true,
					loading: false
				},
				{
					label: 'Etapas',
					action: 'new-step',
					icon: 'mdi-sitemap',
					color: 'success',
					display: true,
					loading: false
				},
				{
					label: 'Clientes',
					action: 'new-client',
					icon: 'mdi-account-cog',
					color: 'grey darken-1',
					display: true,
					loading: false
				},
				{
					label: 'Ver Dados',
					action: 'ver-dados',
					icon: 'mdi-eye',
					color: 'purple accent-4',
					display: true,
					loading: false
				},
			],
		};
	},
	methods: {
		selectOnShift,
		newTrackProjeto: function () {
			if (this.$store.state.infoUser.current.departamento !== 2 && this.$store.state.infoUser.current.departamento !== 5) {
				this.$toast.warning("Sem permissão para criar novo projeto.")
				return;
			};
			this.modalFormProjeto.title = "Novo Mapeamento";
			this.modalFormProjeto.isOpen = true;
		},
		editTrackProjeto: async function (item) {
			try {
				if (this.$store.state.infoUser.current.departamento !== 2 && this.$store.state.infoUser.current.departamento !== 5) {
					this.$toast.warning("Sem permissão para editar projeto.")
					return;
				};
				this.modalFormProjeto.title = "Editar Mapeamento";
				this.modalFormProjeto.isOpen = true;
				this.modalFormProjeto.isLoading = true;
				const response = await api.get(`/trackprojetos/${item.id}`);
				this.dataTrackProjetos.id = response.data.ID;
				this.dataTrackProjetos.idTipoProjeto = response.data.ID_TIPO_PROJETO;
				this.dataTrackProjetos.descricao = response.data.DESCRICAO;
				this.dataTrackProjetos.idStatusRedmine = response.data.ID_STATUS_REDMINE;
				this.dataTrackProjetos.prioridade = response.data.PRIORIDADE;
				this.dataTrackProjetos.progresso = response.data.PROGRESSO;
				this.dataTrackProjetos.idTarefaRedmine = response.data.ID_TAREFA_REDMINE;
				this.dataTrackProjetos.observacao = response.data.OBSERVACAO;
				this.dataTrackProjetos.dataIni = response.data.DATAINI ? response.data.DATAINI.slice(0, 10) : '';
				this.dataTrackProjetos.dataFim = response.data.DATAFIM ? response.data.DATAFIM.slice(0, 10) : '';
				this.dataTrackProjetos.idStatus = response.data.ID_STATUS === "A" ? true : false;
			} catch (err) {
				this.$toast.error('Falha ao comunicar com o banco de dados!');
				this.modalFormProjeto.isOpen = false;
				console.log(err)
			} finally {
				this.modalFormProjeto.isLoading = false;
			}
		},
		newStep: async function (item) {
			try {
				if (this.$store.state.infoUser.current.departamento !== 2 && this.$store.state.infoUser.current.departamento !== 5) {
					this.$toast.warning("Sem permissão para criar nova etapa.")
					return;
				};
				this.modalFormEtapas.title = "Nova Etapa";
				this.modalFormEtapas.isOpen = true;
				this.modalFormEtapas.isLoading = true;
				this.dataFormEtapa.idAcompanhamentoProjeto = item.id;
				const response = await api.get(`/trackprojetos-steps?data=true`);
				this.dsResponsavelEtapa = response.data.users;
			} catch (err) {
				this.$toast.error('Falha ao comunicar com o banco de dados!');
				this.modalFormEtapas.isOpen = false;
				console.log(err)
			} finally {
				this.modalFormEtapas.isLoading = false;
			}
		},
		editStep: async function (item) {
			try {
				if (this.$store.state.infoUser.current.departamento !== 2 && this.$store.state.infoUser.current.departamento !== 5) {
					this.$toast.warning("Sem permissão para editar etapa.")
					return;
				};
				this.modalFormEtapas.title = "Editar Etapa";
				this.modalFormEtapas.isOpen = true;
				this.modalFormEtapas.isLoading = true;
				const response = await api.get(`/trackprojetos-steps/${item.id}`);
				this.dsResponsavelEtapa = response.data.data.users;
				this.dataFormEtapa.id = response.data.id;
				this.dataFormEtapa.idAcompanhamentoProjeto = response.data.idAcompanhamentoProjeto;
				this.dataFormEtapa.descricao = response.data.descricao;
				this.dataFormEtapa.progresso = response.data.progresso;
				this.dataFormEtapa.idResponsavel = response.data.idResponsavel;
				this.dataFormEtapa.observacao = response.data.observacao;
				this.dataFormEtapa.idStatus = response.data.idStatus === 'A';
				this.dataFormEtapa.dataInicial = response.data.dataInicial;
				this.dataFormEtapa.dataFinal = response.data.dataFinal;
			} catch (err) {
				this.$toast.error('Falha ao comunicar com o banco de dados!');
				this.modalFormEtapas.isOpen = false;
				console.log(err)
			} finally {
				this.modalFormEtapas.isLoading = false;
			}
		},
		deleteStep: async function (item) {
			try {
				if (this.$store.state.infoUser.current.departamento !== 2 && this.$store.state.infoUser.current.departamento !== 5) {
					this.$toast.warning("Sem permissão para excluir etapa.")
					return;
				};
				this.modalVerDados.isLoading = true;
				await api.patch(`/trackprojetos-steps/${item.id}`);
				this.$toast.success(`Etapa excluída com sucesso!`);
				this.loadDataGridVerDados(0);
			} catch (err) {
				this.$toast.error('Falha ao comunicar com o banco de dados!');
				console.log(err);
			} finally {
				this.modalVerDados.isLoading = false;
			}
		},
		newClient: async function (item) {
			try {
				this.modalFormClientes.title = "Novos Clientes Solicitantes";
				this.modalFormClientes.isOpen = true;
				this.modalFormClientes.isLoading = true;
				this.dataFormClientes.idAcompanhamentoProjeto = item.id;
				const response = await api.get(`/trackprojetos-clients?idTrackProject=${item.id}&data=true`);
				this.dsClientesSolicitantes = response.data.clients;
			} catch (err) {
				this.$toast.error('Falha ao comunicar com o banco de dados!');
				this.modalFormClientes.isOpen = false;
				console.log(err)
			} finally {
				this.modalFormClientes.isLoading = false;
			}
		},
		editClient: async function (item) {
			try {
				await api.put('/trackprojetos-clients', {
					id: item.id,
					observacao: item.observacao
				});
				this.$toast.success(`Observação alterada com sucesso!`);
				this.loadDataGridVerDados(1);
			} catch (err) {
				this.$toast.error('Falha ao comunicar com o banco de dados!');
				this.modalFormClientes.isOpen = false;
				console.log(err)
			} finally {
				this.modalFormClientes.isLoading = false;
			}
		},
		deleteClient: async function (item) {
			try {
				this.modalVerDados.isLoading = true;
				await api.delete(`/trackprojetos-clients/${item.id}`);
				this.$toast.success(`Cliente excluído com sucesso!`);
				this.loadDataGridVerDados(1);
			} catch (err) {
				this.$toast.error('Falha ao comunicar com o banco de dados!');
				console.log(err);
			} finally {
				this.modalVerDados.isLoading = false;
			}
		},
		openVerDados: function (item, tab: 0 | 1 = 0) {
			this.modalVerDados.title = `Projeto - ${item.descricao}`;
			this.modalVerDados.observacaoProjeto = item.observacao;
			this.modalVerDados.isOpen = true;
			this.modalVerDados.idAcompanhamentoProjeto = item.id;
			this.modalVerDados.tabs = tab;
			this.loadDataGridVerDados(tab);
		},
		saveTrackProjetos: async function () {
			try {
				this.modalFormProjeto.isLoading = true;
				if (this.$refs.formTrackProjeto.validate()) {
					const [metodo, mensagem] = this.dataTrackProjetos.id ? ['put', 'alterado'] : ['post', 'criado'];
					this.dataTrackProjetos.idStatus = this.dataTrackProjetos.idStatus ? 'A' : 'I';
					await api[metodo]('/trackprojetos', this.dataTrackProjetos);
					const response = await api.get('/trackprojetos');
					this.trackProjetos = response.data;
					this.$toast.success(`Mapeamento ${mensagem} com sucesso!`);
					this.modalFormProjeto.isOpen = false;
				} else {
					this.$toast.warning('Formulário inválido! Verifique os dados.');
				}
			} catch (err) {
				this.$toast.error('Falha ao comunicar com o banco de dados!');
				console.log(err);
			} finally {
				this.modalFormProjeto.isLoading = false
			}
		},
		saveStep: async function () {
			try {
				this.modalFormEtapas.isLoading = true;
				if (this.$refs.formEtapas.validate()) {
					const [metodo, mensagem] = this.dataFormEtapa.id ? ['put', 'alterada'] : ['post', 'criada'];
					this.dataFormEtapa.idStatus = this.dataFormEtapa.idStatus ? 'A' : 'I';
					this.dataFormEtapa.progresso = Number(this.dataFormEtapa.progresso);
					await api[metodo]('/trackprojetos-steps', this.dataFormEtapa);

					this.$toast.success(`Etapa ${mensagem} com sucesso!`);
					this.modalFormEtapas.isOpen = false;
					if (this.modalVerDados.isOpen) {
						this.loadDataGridVerDados(0);
					}
				} else {
					this.$toast.warning('Formulário inválido! Verifique os dados.');
				}
			} catch (err) {
				this.$toast.error('Falha ao comunicar com o banco de dados!');
				console.log(err);
			} finally {
				this.modalFormEtapas.isLoading = false
			}
		},
		saveClient: async function () {
			try {
				this.modalFormClientes.isLoading = true;
				if (this.$refs.formClientes.validate()) {
					const [metodo, mensagem] = this.dataFormClientes.id ? ['put', 'alterados'] : ['post', 'criados'];
					await api[metodo]('/trackprojetos-clients', this.dataFormClientes);

					this.$toast.success(`Clientes solicitantes ${mensagem} com sucesso!`);
					this.modalFormClientes.isOpen = false;
					if (this.modalVerDados.isOpen) {
						this.loadDataGridVerDados(1);
					} else {
						this.loadDataGridTrackProjetos()
					}
				} else {
					this.$toast.warning('Formulário inválido! Verifique os dados.');
				}
			} catch (err) {
				this.$toast.error('Falha ao comunicar com o banco de dados!');
				console.log(err);
			} finally {
				this.modalFormClientes.isLoading = false
			}
		},
		handleFilter: async function () {
			try {
				this.modalFiltros.isLoading = true;
				const response = await api.post('/trackprojetos/filter', this.dadosFiltros);
				this.trackProjetos = response.data;
				this.modalFiltros.isOpen = false;
			} catch (err) {
				console.log(err)
				this.$toast.error('Falha ao comunicar com o banco de dados!');
			} finally {
				this.modalFiltros.isLoading = false;
			}
		},
		handleActions: function (action: TActions, item) {
			switch (action) {
				case 'edit-track':
					this.editTrackProjeto(item);
					break;
				case 'new-step':
					this.newStep(item || { id: this.modalVerDados.idAcompanhamentoProjeto });
					break;
				case 'edit-step':
					this.editStep(item);
					break;
				case 'delete-step':
					this.deleteStep(item);
					break;
				case 'new-client':
					this.newClient(item || { id: this.modalVerDados.idAcompanhamentoProjeto });
					break;
				case 'edit-client':
					this.editClient(item);
					break;
				case 'delete-client':
					this.deleteClient(item);
					break;
				case 'ver-dados':
					this.openVerDados(item);
					break;
				default:
					this.$toast.error('Opção de ação inválida!');
			};
		},
		iconPrioridade(prioridade: 0 | 1 | 2 | 3 | 4 | 5) {
			switch (prioridade) {
				case 0:
				case 1:
					return 'mdi-fire'
				case 2:
					return 'mdi-arrow-up-bold'
				case 3:
				case 4:
					return 'mdi-equal'
				case 5:
					return 'mdi-arrow-down-bold'
				default:
					return 'mdi-alert-box'
			}
		},
		iconTipoProjeto(tipoProjeto: 'P' | 'T') {
			switch (tipoProjeto) {
				case 'P':
					return 'mdi-rocket-launch'
				case 'T':
					return 'mdi-code-tags'
				default:
					return 'mdi-alert-box'
			}
		},
		loadDataGridTrackProjetos: async function () {
			try {
				this.loadingGrid = true;
				const [tracks, dados] = await Promise.all([
					api.get("/trackprojetos"),
					api.get("/trackprojetos/data")
				]);
				this.trackProjetos = tracks.data;
				this.dsTipoProjeto = dados.data.tipoProjeto;
				this.dsStatusTarefas = dados.data.status;
			} catch (err) {
				this.$toast.error('Falha ao comunicar com o banco de dados!');
				console.log(err);
			} finally {
				this.loadingGrid = false;
			}
		},
		loadDataGridVerDados: async function (tab: 0 | 1) {
			try {
				this.modalVerDados.isLoading = true;
				switch (tab) {
					case 0:
						const responseSteps = await api.get(`/trackprojetos-steps?idTrackProject=${this.modalVerDados.idAcompanhamentoProjeto}`);
						this.modalVerDados.dataset = responseSteps.data;
						break;
					case 1:
						const responseClients = await api.get(`/trackprojetos-clients?idTrackProject=${this.modalVerDados.idAcompanhamentoProjeto}`);
						this.modalVerDados.dataset = responseClients.data;
						break;
					default:
						this.$toast.error('Opção de tab inválida!');
				};
			} catch (err) {
				this.$toast.error('Falha ao comunicar com o banco de dados!');
				console.log(err);
			} finally {
				this.modalVerDados.isLoading = false;
			}
		},
		closeModalVerDados: function () {
			this.modalVerDados = {
				title: "",
				isOpen: false,
				isLoading: false,
				idAcompanhamentoProjeto: 0,
				observacaoProjeto: "",
				dataset: [],
				tabs: 0
			};
			this.loadDataGridTrackProjetos();
		}
	},
	watch: {
		'modalFormProjeto.isOpen': function (newValue, oldValue) {
			if (newValue) {
				this.dataTrackProjetos = {
					id: 0,
					idTipoProjeto: 0,
					descricao: "",
					idStatusRedmine: 0,
					prioridade: 0,
					progresso: 0,
					idTarefaRedmine: null,
					observacao: "",
					dataIni: "",
					dataFim: "",
					idStatus: true
				}
			}
		},
		'modalFormEtapas.isOpen': function (newValue, oldValue) {
			if (!newValue) {
				this.dataFormEtapa = {
					id: 0,
					idAcompanhamentoProjeto: 0,
					descricao: "",
					progresso: 0,
					idResponsavel: 0,
					observacao: "",
					idStatus: true,
					dataInicial: "",
					dataFinal: "",
				}
			}
		},
		'modalFormClientes.isOpen': function (newValue, oldValue) {
			if (!newValue) {
				this.dataFormClientes = {
					id: 0,
					idAcompanhamentoProjeto: 0,
					idClients: [],
					observacao: ""
				}
			}
		}
	},
	mounted() {
		this.loadDataGridTrackProjetos();
		store.commit(M_MENU, "Mapeamento de Projetos");
	},
	components: {
		Grid,
		ModalCrud,
		ModalVerDados
	}
})

