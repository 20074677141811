

import Vue, { computed } from 'vue';
import Toolbar from '@/components/NavToolbar.vue';
import Notify from '@/components/Notify.vue';
import Loader from '@/components/Loader.vue';
import DrawerUserTasks from '@/components/UsersTasks/DrawerUserTasks.vue'
import { store } from '@/store';

export default Vue.extend({
  name: 'App',
  data() {
    return {
      drawer: false
    }
  },
  components: {
    Notify,
    Toolbar,
    Loader,
  },
  methods: {
    
  },
  setup() {
    const menuAtual = computed(() => store.state.currentMenu)
    return {
      menuAtual
    }
  }
});
