

import api from '@/api/api';
import NFSeModalSend from './NFSeModalSend.vue';
import CargaDados from '../CargaDados/CargaDados.vue';
import { columns } from '@/utils/columns';
import { dateFunctions } from '@/utils/dateFunctions';
import { defineComponent } from 'vue';
import { baseURLAPI } from '@/api/api';


export default defineComponent({
	name: "NFSeList",
	data() {
		return {
			contas: [],
			centroCusto: '',
			tipoDocumento: '',
			statusDocumento: '',
			search: "",
			loading: false,
			mesAno: "",
			items: [],
			tabModal: '',
			tiposCentroCustos: [],
			showModal: false,
			showModalInfo: false,
			loadingDownload: false,
			tabDetails: null,
			showModalCarga: false,
			showModalConfirmExclusion: false,
			operationModal: 'send',
			idUnique: [],
			dadosConta: {}
		};
	},
	watch: {
		'statusDocumento': async function (newValue, oldValue) {
			this.contas = this.items = [];
			if (this.centroCusto.length && this.tipoDocumento.length && this.statusDocumento.length && this.mesAno.length) {
				await this.updateData();
			}
		},
		'tipoDocumento': async function (newValue, oldValue) {
			this.contas = this.items = [];
			if (this.centroCusto.length && this.tipoDocumento.length && this.statusDocumento.length && this.mesAno.length) {
				await this.updateData()
			}
		},
		'centroCusto': async function (newValue, oldValue) {
			this.contas = this.items = [];
			if (this.centroCusto.length && this.tipoDocumento.length && this.statusDocumento.length && this.mesAno.length) {
				await this.updateData()
			}
		},
		'mesAno': async function (newValue, oldValue) {
			this.contas = this.items = [];
			await this.findCentroCustos();
			if (this.centroCusto.length && this.tipoDocumento.length && this.statusDocumento.length && this.mesAno.length) {
				await this.updateData()
			}
		}

	},
	methods: {
		closeModal() {
			this.contas = [];
			this.showModal = false;
		},
		async updateData() {
			await this.loadData();
		},
		openForCheck() {
			if (!this.contas.length) {
				this.$toast.error("Selecione pelo menos uma conta!");
				return;
			}
			this.operationModal = 'send'

			this.showModal = true
		},
		closeModalCarga() {
			this.showModalCarga = false;
		},
		async checkStatus() {
			try {
				if (!this.items.length) {
					this.$toast.warning("Não há mais NFS-e para atualizar status.");
					return;
				}
				this.loading = true;
				const data = {
					contas: this.items.map((x) => x.ID_REF_PARCELA_CONTA),
					idStatus: 2,
					tipoEmissao: this.tipoDocumento,
					observacao: "Emissão."
				}
				await api.put('/nfse', data);
				this.$toast.success("Notas emitidas atualizadas!");
				await this.updateData();
			} catch (err) {
				this.items = [];
				await this.updateData();
				this.$toast.error("Houve uma falha no processo");
			} finally {
				this.loading = false;
			}
		},
		async moreInformation(item) {
			try {
				this.showModalInfo = true;
				const response = await api.get(`nfse/${item.ID_REF_PARCELA_CONTA}`);
				this.dadosConta = response.data;
			} catch (err) {
				console.log(err);
				this.$toast.error("Falha ao comunicar com o banco de dados!");
			}
		},
		async findCentroCustos() {
			let formatFilter = this.mesAno.split('-');
			formatFilter = formatFilter[1] + '/' + formatFilter[0];
			const getTipos = await api.get(`/nfse/centrocustos?mesAno=${formatFilter}`);

			this.tiposCentroCustos = getTipos.data;

			if (!this.centroCusto && this.tiposCentroCustos.length > 0) {
				this.centroCusto = this.tiposCentroCustos[0].TIPO_CONTA;
			}
		},
		async loadData() {
			let formatFilter = this.mesAno.split('-');
			formatFilter = formatFilter[1] + '/' + formatFilter[0];
			try {
				this.loading = true;
				const getTipos = await api.get(`/nfse/centrocustos?mesAno=${formatFilter}`);
				this.tiposCentroCustos = getTipos.data;
				if (this.tiposCentroCustos.length && this.tipoDocumento === '' && this.statusDocumento === '') {
					this.centroCusto = this.tiposCentroCustos[0].TIPO_CONTA;
					this.tipoDocumento = 'NFS-e & Boleto',
						this.statusDocumento = 'Em aberto'
				}
				if (this.centroCusto.length && this.tipoDocumento.length && this.statusDocumento.length) {
					let filterStatus = null;
					switch (this.statusDocumento) {
						case 'Em aberto':
							filterStatus = 1;
							break;
						case 'Enviado':
							filterStatus = 2;
							break;
						case 'Emitidas':
							filterStatus = 3;
							break;
						default:
							filterStatus = 1;
					}
					const getInfo = await api.get(`/nfse/?mesAno=${this.mesAno.slice(5, 7) + "/" + this.mesAno.slice(0, 4)}&tipoRegistro=${encodeURIComponent(this.centroCusto)}&tipoEmissao=${encodeURIComponent(this.tipoDocumento)}&status=${encodeURIComponent(filterStatus)}`);
					this.items = getInfo.data;
				} else {
					this.$toast.warning("Informe os filtros antes de prosseguir.");
				}
			} catch (err) {
				this.$toast.error("Falha ao comunicar com o banco de dados!");
				console.log(err)
			} finally {
				this.loading = false;
			}
		},
		async downloadFile(tipoEvento, haveId?: number) {
			try {
				this.loadingDownload = true;
				if (haveId) {
					window.open(baseURLAPI + `/nfse-download-file?id=${haveId}&tipo=${tipoEvento}`);
				} else {
					window.open(baseURLAPI + `/nfse-download-file?id=${this.dadosConta.ID_REF_PARCELA_CONTA}&tipo=${tipoEvento}`);
				}
				
			} catch (err) {
				console.log(err);
				this.$toast.error("Falha ao comunicar com o banco de dados!");
			} finally {
				this.loadingDownload = false;
			}
		},
		async deleteConta(idContas, option) {
			try {
				if (option === 'unique') {
					let contas = idContas
					switch (typeof (idContas)) {
						case 'number':
							contas = [idContas]
							break;
						case 'object':
							contas = idContas
							break;
						default:
							contas = idContas;
					}
					if (this.idUnique.length === 0) {
						this.idUnique = contas;
						this.showModalConfirmExclusion = true;
					} else {
						await api.delete('/nfse', {
							data: {
								ids: contas
							}
						});
						await this.updateData();
						this.idUnique = [];
						this.$toast.success("Documento excluído!");
						this.showModalConfirmExclusion = false;
					}


				} else {
					if (!this.contas.length) {
						this.$toast.error("Selecione pelo menos uma conta!");
						return;
					}
					this.operationModal = 'delete'
					this.showModal = true;
				}

			} catch (error) {
				this.$toast.error("Falha ao comunicar com o banco de dados!");
			}
		},
		async resendEmail(idConta) {
			try {
				await api.post('/nfse/send-mail', {
					id: idConta
				});
				await this.updateData();
				this.$toast.success("Evento enviado.");
			} catch (error) {
				this.$toast.error("Falha ao comunicar com o banco de dados!");
			}
		},
		async sendMailRecibos() {
			try {
				this.loading = true;
				if (!this.contas.length) {
					this.$toast.error("Selecione pelo menos uma conta!");
					return;
				}
				const mappedIds = this.contas.map(x => x.ID_REF_PARCELA_CONTA);
				await api.post('/nfse/send-mail-recibo', {
					ids: mappedIds
				});
				this.contas = [];
				this.$toast.success("Evento enviado.");
			} catch (err) {
				this.$toast.error("Falha ao comunicar com o banco de dados!");
				console.log(err)
			} finally {
				this.loading = false;
			}
		}
	},
	async mounted() {
		try {
			const currentDate = dateFunctions.getDateNow();
			this.mesAno = currentDate.slice(0, 7);
			this.loading = true;
			this.loadData();
		}
		catch (err) {
			console.log(err);
			this.$toast.error("Falha ao comunicar com o banco de dados!");
		}
		finally {
			this.loading = false;
		}
	},
	setup() {
		return {
			columns
		};
	},
	components: {
		NFSeModalSend,
		CargaDados
	}
})
