

import { defineComponent } from 'vue';
import TabResumoMensal from '@/views/Dashboards/Financeiro/DashFinanceiroTabs/TabResumoMensal.vue'
import TabDRE from '@/views/Dashboards/Financeiro/DashFinanceiroTabs/TabDRE.vue'
import { store } from '@/store';
import { M_MENU } from '@/store/mutations-types';

export default defineComponent({
	name: 'DashBoletos',
	data() {
		return {
			tabs: 99,
		}
	},
	components: {
		TabResumoMensal,
		TabDRE
	},
	mounted() {
		store.commit(M_MENU, `Financeiro - Visão Geral`);
	}
})

