

import api from '@/api/api';
import { store } from '@/store';
import { M_MENU } from '@/store/mutations-types';
import { defineComponent } from 'vue';
import VueApexCharts from 'vue-apexcharts';
import selectOnShift from '@/utils/selectOnShift';
import ModalCrud from '@/components/ModalCrud.vue';

export default defineComponent({
    name: 'DashPlanejadoRealizadoView',
    components: {
        apexchart: VueApexCharts,
        ModalCrud
    },
    data() {
        return {
            modalFilterOpened: false,
            modalFilterLoading: false,
            exibeFiltro: false,
            search: null,
            dsSprintFiltro: [],
            sprintsToFilter: [],
            dsPlanejadas: [],
            dsEfetivadas: [],
            dsTotalizador: [],
            filtro: {
                dataInicio: '',
                dataFim: '',
                sprints: []
            }
        }
    },
    computed: {
        corLabel() {
            let cor
            if (this.$vuetify.theme.dark) {
                cor = '#FFFFFF'
            } else {
                cor = '#000000'
            }
            return cor
        },
        optionsPlanejadoRealizadoMeta() {
            return {
                chart: {
                    type: 'line',
                    foreColor: this.corLabel,
                    toolbar: {
                        show: true
                    },
                },
                responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: "100%"
                        },
                        legend: {
                            position: 'bottom'
                        }
                    }
                }],
                title: {
                    text: 'Planejado x Realizado',
                    align: 'center',
                    margin: 0,
                    offsetX: 0,
                    offsetY: 0,
                    floating: false,
                    style: {
                        fontSize: '20px',
                        fontWeight: 'bold',
                        color: this.corLabel
                    },
                },
                legend: {
                    position: 'top'
                },
                dataLabels: {
                    enabled: true,
                },
                labels: this.dsPlanejadas.map((x) => x.DESCSPRINT),
                theme: {
                    palette: 'palette6' // upto palette10
                }
            }
        },
        optionsMetaPorSprint() {
            return {
                chart: {
                    type: 'line',
                    foreColor: this.corLabel,
                    toolbar: {
                        show: true
                    }
                },
                legend: {
                    position: 'top'
                },
                responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: "100%"
                        },
                        legend: {
                            position: 'top'
                        }
                    }
                }],
                title: {
                    text: 'Meta por Sprint',
                    align: 'center',
                    margin: 0,
                    offsetX: 0,
                    offsetY: 0,
                    floating: false,
                    style: {
                        fontSize: '20px',
                        fontWeight: 'bold',
                        color: this.corLabel
                    },
                },
                dataLabels: {
                    enabled: true,
                    enabledOnSeries: [0]
                },
                labels: this.dsEfetivadas.map((x) => x.DESCSPRINT),
                theme: {
                    palette: 'palette1' // upto palette10
                }
            }
        },
        planejadoRealizadoMetaSeries() {
            return [
                {
                    name: 'Planejado',
                    type: 'column',
                    data: this.dsPlanejadas.map((x) => x.PREVISTAS)
                },
                {
                    name: 'Realizado',
                    type: 'column',
                    data: this.dsPlanejadas.map((x) => x.ENTREGUES)
                },
                {
                    name: 'Meta',
                    type: 'line',
                    data: this.dsPlanejadas.map((x) => x.VQTDMETA)
                },
            ]
        },
        metaPorSprintSeries() {
            return [
                {
                    name: 'Percentual',
                    type: 'column',
                    data: this.dsEfetivadas.map((x) => x.vPercent)
                },
                {
                    name: 'Meta',
                    type: 'line',
                    data: this.dsEfetivadas.map((x) => x.vPercentualMeta)
                },
            ]
        }

    },
    methods: {
        changeExibeFiltro: function () {
            this.exibeFiltro = !this.exibeFiltro
        },
        handleFilter: async function () {
            try {
                this.modalFilterLoading = true;
                const response = await api.post('/dash/resultadoMeta', this.filtro);
                this.dsPlanejadas = response.data.dadosPlanejados;
                this.dsEfetivadas = response.data.dadosMeta;
                this.dsTotalizador = response.data.totalizador;
                this.modalFilterOpened = false;
            } catch (err) {
                console.log(err);
            } finally {
                this.modalFilterLoading = false;
            }
        },
        selectOnShift,
    },
    async mounted() {
        if (this.dsPlanejadas.length === 0 || this.dsEfetivadas.length === 0 || this.dsSprintFiltro.length === 0) {
            const [dados, sprints] = await Promise.all([
                api.post('/dash/resultadoMeta'),
                api.get('/sprints')
            ])
            this.dsPlanejadas = dados.data.dadosPlanejados;
            this.dsEfetivadas = dados.data.dadosMeta;
            this.dsTotalizador = dados.data.totalizador;
            this.dsSprintFiltro = sprints.data;
        }
        store.commit(M_MENU, `DashBoard Planejado x Meta`);
    },
})

