import { render, staticRenderFns } from "./ClientesView.vue?vue&type=template&id=69fbe071&scoped=true&"
import script from "./ClientesView.vue?vue&type=script&lang=ts&"
export * from "./ClientesView.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "69fbe071",
  null
  
)

export default component.exports