
import selectOnShift from "@/utils/selectOnShift";
import api from '@/api/api'
import { M_MENU } from '@/store/mutations-types';
import { defineComponent } from 'vue';
import ModalCrud from "@/components/ModalCrud.vue";
export default defineComponent({
	name: 'CalendarioView',
	data: () => ({
		focus: '',
		type: 'month',
		typeToLabel: {
			month: 'Mês',
			week: 'Sêmana',
			day: 'Dia',
			'4day': '4 Dias',
		},
		deslocamento: '',
		cliente: '',
		regiao: '',
		atividade: '',
		dataInicio: '',
		horaInicio: '',
		dataFim: '',
		horaFim: '',
		selectedEvent: {},
		selectedElement: null,
		selectedOpen: false,
		events: [],
		usersToFilter: [],
		dsUserToFilter: [],
		showFilter: false,
		modals: {
			filter: {
				open: false,
				isLoading: false,
				data: {
					form: {
						users: [],
						atividade: [],
						veiculo: [],
						departamento: [],
						dataInicio: '',
						dataFim: '',
						isValid: false
					},
					inputs: {
						optionsUsers: [],
						optionsAtividades: [],
						optionsVeiculos: [],
						optionsDepartamentos: []
					}
				}
			}
		}
	}),
	async mounted() {
		this.$refs.calendar.checkChange();
		const response = await api.post('/dash/calendarioOndeEstou', { users: this.usersToFilter });
		const users = await api.get('/userstofilter');
		this.dsUserToFilter = users.data;
		this.events = response.data;
		this.$store.commit(M_MENU, 'Calendário');
	},
	methods: {
		viewDay({ date }) {
			this.focus = date;
			this.type = 'day';
		},
		getEventColor(event) {
			return event.color;
		},
		setToday() {
			this.focus = '';
		},
		prev() {
			this.$refs.calendar.prev();
		},
		selectOnShift,
		next() {
			this.$refs.calendar.next();
		},
		showEvent({ nativeEvent, event }) {
			const open = async () => {
				this.selectedEvent = event;
				this.selectedElement = nativeEvent.target;
				const response = await api.get(`/detailcalendar/${event.id}`);
				this.cliente = response.data.cliente;
				this.atividade = response.data.atividade;
				this.deslocamento = response.data.carro;
				this.regiao = response.data.regiao;
				this.dataInicio = response.data.dataInicio;
				this.horaInicio = response.data.horaInicio;
				this.dataFim = response.data.dataFim;
				this.horaFim = response.data.horaFim;
				requestAnimationFrame(() => requestAnimationFrame(() => this.selectedOpen = true));
			};
			if (this.selectedOpen) {
				this.selectedOpen = false;
				requestAnimationFrame(() => requestAnimationFrame(() => open()));
			}
			else {
				open();
			}
			nativeEvent.stopPropagation();
		},
		async handleModal() {
			try {
				this.modals.filter.open = true;
				this.modals.filter.isLoading = true;
				const { data } = await api.get('/dash/calendarioOndeEstou');
				this.modals.filter.data.inputs.optionsUsers = data.users;
				this.modals.filter.data.inputs.optionsAtividades = data.atividades;
				this.modals.filter.data.inputs.optionsVeiculos = data.veiculos;
				this.modals.filter.data.inputs.optionsDepartamentos = data.departamentos;
			} catch (error) {
				this.$toast.error('Falha ao comunicar com o banco de dados!');
			} finally {
				this.modals.filter.isLoading = false;
			}
		},
		async handleFilter() {
			try {
				this.modals.filter.isLoading = true;
				const { data } = await api.post('/dash/calendarioOndeEstou', this.modals.filter.data.form);
				this.events = data;
				this.modals.filter.open = false;
				this.modals.filter.data.inputs.optionsUsers = [];
				this.modals.filter.data.inputs.optionsAtividades = [];
				this.modals.filter.data.inputs.optionsVeiculos = [];
				this.modals.filter.data.inputs.optionsDepartamentos = [];
			} catch (error) {
				this.$toast.error('Falha ao comunicar com o banco de dados!');
			} finally {
				this.modals.filter.isLoading = false;
			}
		}
	},
	components: { ModalCrud }
})
