export const dateFunctions = {
    getTimeNow: () => {
        const data = new Date();
        return new Date(data.getTime() - (240 * 60000)).toISOString().slice(11, 19)
    },
    getDateNow: () => {
        const data = new Date();
        return new Date(data.getTime() - (240 * 60000)).toISOString().slice(0, 10)
    },
    getDateTimewNow: () => {
        const data = new Date();
        return new Date(data.getTime() - (240 * 60000)).toISOString().slice(0, 19);
    },
    getFirstAndLastDayWeek: (week: number, year: number) => {
        const firstDayOfYear = new Date(year, 0, 1);

        const diff = (week - 1) * 7;

        const firstDayOfWeek = new Date(firstDayOfYear.getTime() + (diff * 24 * 60 * 60 * 1000));

        const lastDayOfWeek = new Date(firstDayOfWeek.getTime() + (6 * 24 * 60 * 60 * 1000));

        return {
            dataInicio: firstDayOfWeek,
            dataFim: lastDayOfWeek
        }
    },
    formatYearMonth: (month: number, year: number) => {
        switch (month) {
            case 0:
                return `Janeiro - ${year}`
            case 1:
                return `Fevereiro - ${year}`
            case 2:
                return `Março - ${year}`
            case 3:
                return `Abril - ${year}`
            case 4:
                return `Maio - ${year}`
            case 5:
                return `Junho - ${year}`
            case 6:
                return `Julho - ${year}`
            case 7:
                return `Agosto - ${year}`
            case 8:
                return `Setembro - ${year}`
            case 9:
                return `Outubro - ${year}`
            case 10:
                return `Novembro - ${year}`
            case 11:
                return `Dezembro - ${year}`
        }
    }
}