

import { store } from '@/store';
import { M_SHOWNAV } from '@/store/mutations-types';
import { defineComponent, computed, ref } from 'vue';
import router from '@/router/index';
import Cookies from 'js-cookie';
import Highcharts from "highcharts";
import Gantt from "highcharts/modules/gantt";
import HighchartsDarkTheme from 'highcharts/themes/dark-unica';
import HighchartsLightTheme from 'highcharts/themes/grid-light';
import DrawerUserTasks from '@/components/UsersTasks/DrawerUserTasks.vue';

export default defineComponent({
    name: 'toolbar',
    data() {
        return {
            enabled: false,
            drawerUsersTasks: false
        }
    },
    props: {
        menuAtual: {
            type: String,
            required: true
        }
    },
    watch: {
        group() {
            this.drawer = false
        },
    },
    methods: {
        switchTheme() {
            this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
            localStorage.setItem('theme', this.$vuetify.theme.dark ? 'dark' : 'light');
            Gantt(Highcharts)
            if (localStorage.getItem('theme') === 'dark') {
                HighchartsDarkTheme(Highcharts)
            } else {
                HighchartsLightTheme(Highcharts)
            }
            Highcharts.setOptions(Highcharts.theme);
        },
        removeCookie() {
            Cookies.remove('csnoteweb');
            this.$router.push('/')
        },
        openCloseModalDrawerUsersTasks() {
            this.drawerUsersTasks = !this.drawerUsersTasks;
        }
    },
    components: {
        DrawerUserTasks
    },
    mounted() {
        this.enabled = this.$vuetify.theme.dark;
    },
    setup() {
        
        const showNav = computed(() => store.state.showNav)
        let drawer = ref(false)
        const changeNav = () => {
            drawer.value = !drawer.value
            store.commit(M_SHOWNAV)
        }
        const changeRoute = (route: string) => {
            router.push(route)
        }
        return {
            showNav,
            changeNav,
            drawer,
            changeRoute
        }
    }
})

