// Current User

export const M_CURRENT_USER = "M_CURRENT_USER"

// Notificações

export const M_NOTIFY = "M_NOTIFY"
export const M_SHOWNAV = "M_SHOWNAV"

export const M_MENU = "M_MENU"
