import 'material-design-icons-iconfont/dist/material-design-icons.css'
import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import pt from 'vuetify/src/locale/pt'

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: 'md',
    },    
    theme: {
        dark: localStorage.getItem('theme') === 'dark'
    },
    lang: {
        locales: { pt },
        current: 'pt',
    },
});
