
import api from '@/api/api';
import { store } from '@/store';
import { M_MENU } from '@/store/mutations-types';
import { defineComponent } from 'vue';
import { chartThemes } from '@/utils/chartThemes';
import { msToTime } from '@/utils/msToTime';
import Highcharts from 'highcharts';
import ModalCrud from '@/components/ModalCrud.vue';
import selectOnShift from '@/utils/selectOnShift';

export default defineComponent({
    name: 'DashGanttProjectsMap',
    components: {
        ModalCrud
    },
    data() {
        return {
            dsTarefas: [],
            dsStatusTarefas: [],
            modalOpened: false,
            modalLoading: false,
            modalFilterOpened: false,
            modalFilterLoading: false,
            dadosTask: {
                descricao: '',
                status: '',
                prioridade: null,
                progresso: '',
                numRedmine: null,
                obs: '',
                dataInicio: '',
                dataFim: ''
            },
            dadosFiltros: {
                statusRedmine: [],
                ano: new Date().getFullYear(),

            },
            chartOptions: {
                chart: {
                    style: {
                        fontFamily: 'Roboto'
                    }
                },
                plotOptions: {
                    gantt: {
                        borderColor: 'transparent',
                        connectorWidth: 0,
                        point: {
                            events: {
                                click: (e) => {
                                    //@ts-ignore
                                    this.showData(e.point.id);
                                },
                            }
                        },
                        dataLabels: {
                            useHTML: true,
                            formatter: function () {
                                if (this.point.parent) {
                                    const comparaData = new Date(this.point.end) < new Date();
                                    let emoji: string;                                    
                                    if (this.point.completed >= 1) {
                                        emoji = '✅'
                                    } else if (comparaData && this.point.completed < 1) {
                                        emoji = '⏲'
                                    } else {
                                        emoji = '⏳'
                                    }
                                    const point = this.point.completed * 100;

                                    return (
                                        '<div class="gantt-data-label">' +
                                        emoji +
                                        '<span style="background-color: #1E1E1E; color: #FFFF; font-size: 12px">' + point.toFixed(2).replaceAll('.', ',') + '%' + '</span>'
                                        +
                                        '</div>'
                                    );
                                }

                            }
                        },
                    }
                },
                tooltip: {
                    enabled: false
                },
                labels: {
                    style: {
                        fontFamily: 'Roboto'
                    }
                },
                xAxis: [
                    {
                        tickInterval: 1000 * 60 * 60 * 24 * 30,
                        min: new Date(new Date().getFullYear(), 0, 1).getTime(),                                                
                        max: new Date(new Date().getFullYear(), 11, 31).getTime(),
                        labels: {
                            align: "center",
                            allowOverlap: true,
                            formatter: function AxisLabelsFormatterCallbackFunction() {                                
                                const providedLocalDate = new Date(this.value);
                                const realDate = new Date(providedLocalDate.getTime() + (providedLocalDate.getTimezoneOffset() * 60 * 1000));
                                const month = realDate.getMonth();
                                let trimestreDescription: string;

                                switch (Math.floor(month / 3)) {
                                    case 0:
                                        trimestreDescription = 'Trimestre 1';
                                        break;
                                    case 1:
                                        trimestreDescription = 'Trimestre 2';
                                        break;
                                    case 2:
                                        trimestreDescription = 'Trimestre 3';
                                        break;
                                    case 3:
                                        trimestreDescription = 'Trimestre 4';
                                        break;
                                    default:
                                        trimestreDescription = 'Invalid date';
                                        break;
                                }

                                return `<div style="font-size: 16px; font-family: Roboto;"><span style="font-weight:bold; color:#0068B5;")}">` + trimestreDescription + `</span></div>`;
                            }
                        },
                        units: [
                            [
                                'month',
                                [3]
                            ]
                        ],
                    },
                    {
                    },],
                series: [{
                    name: 'Tarefas',
                    data: []
                }],
                credits: false
            }
        };
    },
    watch: {
        '$vuetify.theme.dark'() {
            this.handleTheme();
        }

    },
    methods: {
        selectOnShift,
        handleTheme() {
            this.$refs.graph.chart.destroy();
            Highcharts.setOptions(!this.$vuetify.theme.dark ? chartThemes.myLightTheme : chartThemes.myDarkTheme);
            this.$refs.graph.chart = Highcharts.ganttChart('dashGantt', this.chartOptions);
            Highcharts.setOptions(Highcharts.merge(Highcharts.theme, chartThemes.myDarkTheme));
        },
        handleFilter() {
            try {                
                this.chartOptions.xAxis[0].min = new Date(this.dadosFiltros.ano, 0, 1).getTime();                
                this.chartOptions.xAxis[0].max = new Date(this.dadosFiltros.ano, 11, 31).getTime();                          
                this.modalFilterLoading = true;
                this.update();
                this.modalFilterOpened = false;                
            } catch (err) {
                this.$toast.error('Falha ao comunicar com o banco de dados!');
                console.log(err)
            } finally {
                this.modalFilterLoading = false;
            }



        },
        showData: async function (id: number) {
            try {
                this.modalLoading = true;
                this.modalOpened = true;
                const response = await api.get(`/trackprojetos/complete/${id}`)
                this.dadosTask = response.data[0];
            } catch (err) {
                this.$toast.error('Falha ao comunicar com o banco de dados!');
                console.log(err)
                this.modalOpened = false;
            } finally {
                this.modalLoading = false;
            }

        },
        async update() {
            const response = await api.post('/dash/trackprojetos', this.dadosFiltros);
            const dadosTracks = response.data;
            delete dadosTracks[0].parent;
            delete dadosTracks[0].progress;
            delete dadosTracks[1].parent;
            delete dadosTracks[1].progress;
            dadosTracks.map((x, i) => {
                if (typeof (x.start) === 'string') {
                    const [anoStart, mesStart, diaStart] = x.start.slice(0, 10).split('-');
                    const [anoEnd, mesEnd, diaEnd] = x.end.slice(0, 10).split('-');                    
                    dadosTracks[i].start = new Date(parseInt(anoStart), parseInt(mesStart) - 1, parseInt(diaStart)).getTime()                    
                    dadosTracks[i].end = new Date(parseInt(anoEnd), parseInt(mesEnd) - 1, parseInt(diaEnd)).getTime();
                }
                dadosTracks[i].completed = parseFloat(dadosTracks[i].completed);
            })            
            this.chartOptions.series[0].data = dadosTracks;            
            this.handleTheme();
        }

    },
    async mounted() {                                                
        store.commit(M_MENU, `Roadmap Projetos`);
        try {
            this.isLoading = true;
            const dataForFilter = await api.get('/trackprojetos/data');
            this.dsStatusTarefas = dataForFilter.data.status;
            this.update();
        } catch (err) {
            console.log(err)
        } finally {
            this.isLoading = false;
        }
    },
});
