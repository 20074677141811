
import { defineComponent } from 'vue';


export default defineComponent({
    name: 'CardTabResumo',
    props: {
        saldoBanco: {
            type: String,
            required: true
        },
        saldoCaixa: {
            type: String,
            required: true
        },
        valorReceberGeral: {
            type: String,
            required: true
        },
        valorPagarGeral: {
            type: String,
            required: true
        },
        saldoGeral: {
            type: String,
            required: true
        }

    }    
});

