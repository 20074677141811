import axios, { AxiosError, AxiosInstance } from 'axios';
//export const baseURLAPI = "https://csnoteapi.controlsoft.com.br";
export const baseURLAPI = "https://csnoteapi.controlsoft.com.br";

const api: AxiosInstance = axios.create({
    baseURL: baseURLAPI
})
api.interceptors.response.use(
    (response) => {
        let message = '';
        if (response.status === 200) {
            switch (response.config.method) {
                case 'delete':
                    message = 'Registro excluído!';
                    break;
                case 'patch':
                case 'put':
                    message = 'Registro alterado!';
                    break;
                case 'post':
                    message = 'Registro criado!';
                    break;
                default:
                    message = 'Ok!';
            }
        } else if (response.status === 201 && response.config.method === 'post') {
            message = 'Registro criado!';
        }
        return { ...response, message };
    },
    (error) => {
        let message = '';
        if (!error.response) {
            message = 'Falha na comunicação!';
        } else if (error.response.status === 401) {
            message = 'Autenticação inválida!';
        } else if (!error.response.data.message) {
            message = `${error.response.status} - Falha na comunicação!`;
        } else if (error.response.data.message) {
            message = error.response.data.message;
        } else {
            return Promise.reject(error);
        }

		Object.assign(error, {message: message});

		return Promise.reject(error);
    }
)
export default api;
