

import { defineComponent } from 'vue';

export default defineComponent({
    name: 'SimpleTable',
    props: {
        columns: {
            type: [],
            required: true
        },
        headerGrande: {
            type: Boolean,
            required: false
        },
        headerGrandeOndeEstou: {
            type: Boolean,
            required: false
        }
    }
})
