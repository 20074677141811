export const chartThemes = {
    myDarkTheme: {
        colors: ['#42A5F5', '#66BB6A', '#EF5350', '#FFA726', '#29B6F6', '#FF7043', '#8D6E63', '#78909C'],
        chart: {
            backgroundColor: '#1E1E1E',
            style: {
                fontFamily: "'Roboto', sans-serif"
            }
        },
        title: {
            style: {
                color: '#FFFFFF',
                font: 'bold 16px "Roboto", sans-serif'
            }
        },
        subtitle: {
            style: {
                color: '#FFFFFF',
                font: 'bold 12px "Roboto", sans-serif'
            }
        },
        xAxis: {
            gridLineColor: '#424242',
            labels: {
                style: {
                    color: '#FFFFFF',
                    font: '16px "Roboto", sans-serif'
                }
            },
            lineColor: '#424242',
            minorGridLineColor: '#616161',
            tickColor: '#424242',
            title: {
                style: {
                    color: '#FFFFFF',
                    font: 'bold 12px "Roboto", sans-serif'
                }
            }
        },
        yAxis: {
            gridLineColor: '#424242',
            labels: {
                style: {
                    color: '#FFFFFF',
                    font: '15px "Roboto", sans-serif'
                }
            },
            lineColor: '#424242',
            minorGridLineColor: '#616161',
            tickColor: '#424242',
            title: {
                style: {
                    color: '#FFFFFF',
                    font: 'bold 12px "Roboto", sans-serif'
                }
            }
        }
    },
    myLightTheme: {
        colors: ['#1976D2', '#388E3C', '#D32F2F', '#F57C00', '#00796B', '#5D4037', '#757575', '#BDBDBD'],
        chart: {
            backgroundColor: '#FFFFFF',
            style: {
                fontFamily: "'Roboto', sans-serif"
            }
        },
        title: {
            style: {
                color: '#000000',
                font: 'bold 16px "Roboto", sans-serif'
            }
        },
        subtitle: {
            style: {
                color: '#666666',
                font: 'bold 12px "Roboto", sans-serif'
            }
        },
        xAxis: {
            gridLineColor: '#E0E0E0',
            labels: {
                style: {
                    color: '#000000',
                    font: '15px "Roboto", sans-serif'
                }
            },
            lineColor: '#E0E0E0',
            minorGridLineColor: '#F0F0F0',
            tickColor: '#E0E0E0',
            title: {
                style: {
                    color: '#000000',
                    font: 'bold 15px "Roboto", sans-serif'
                }
            }
        },
        yAxis: {
            gridLineColor: '#E0E0E0',
            labels: {
                style: {
                    color: '#000000',
                    font: '15px "Roboto", sans-serif'
                }
            },
            lineColor: '#E0E0E0',
            minorGridLineColor: '#F0F0F0',
            tickColor: '#E0E0E0',
            title: {
                style: {
                    color: '#000000',
                    font: 'bold 11px "Roboto", sans-serif'
                }
            }
        }
    }
}