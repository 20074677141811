

import { defineComponent } from 'vue';
import ModalCrud from './../ModalCrud.vue';
import { formsValidations } from '../../utils/formsValidations';
import api from '@/api/api';

export default defineComponent({
    name: 'ModalUserTasks',
    components: {
        ModalCrud
    },
    data() {
        return {
            data: {
                isLoading: false,
                isValid: false,
                inputs: {
                    users: [],
                    priority: []
                },
                form: {
                    id: null,
                    descricao: '',
                    idPrioridade: null,
                    idUsuario: null,
                    idStatus: true
                }
            },
            isOpen: true
        }
    },
    props: {
        operation: {
            type: String,
            required: true
        },
        id: {
            type: Number,
            required: false
        }
    },
    methods: {
        async handleSave() {
            try {
                await this.$refs.form.validate();
                this.$store.state.loading = true;                
                if (!this.data.isValid) {
                    this.$toast.warning('Formulário inválido! Verifique os dados.');
                    return;
                }
                this.data.form.idStatus = this.data.form.idStatus ? 'A' : 'I';
                await api[this.operation]('/user-task', this.data.form);
                const message = this.operation === 'post' ? 'criada' : 'alterada';
                this.$toast.success(`Atividade ${message} com sucesso!`);
                this.$emit('saved-task');
            } catch (error) {
                this.$toast.error('Falha ao salvar atividade!');
            } finally {
                this.$store.state.loading = false;
            }
        }
    },
    async mounted() {
        try {
            this.$store.state.loading = true;
            if (this.$props.operation === 'post') {
                const response = await api.get(`/user-task?request=data&id=${this.$store.state.infoUser.current.id}`);
                this.data.inputs.users = response.data.users;
                this.data.inputs.priority = response.data.priority;
                this.data.form.idUsuario = this.$store.state.infoUser.current.id;
            } else {
                const response = await api.get(`/user-task/${this.$props.id}`);                
                this.data.inputs.users = response.data.users;
                this.data.inputs.priority = response.data.priority;
                this.data.form = {
                    id: response.data.task.CODIGOTAREFA,
                    descricao: response.data.task.DESCRICAOTAREFA,
                    idPrioridade: response.data.task.id_prioridade,
                    idUsuario: response.data.task.CODIGO_USUARIO,
                    idStatus: response.data.task.CODIGO_STATUS === 'A'
                };                
            }
        } catch (error) {
            this.$toast.error('Falha ao carregar os dados!');
        } finally {
            this.$store.state.loading = false;
        }
    },
    setup() {
        return {
            formsValidations
        }
    }
})
