

import { defineComponent } from 'vue';
import { store } from '@/store';
import { M_MENU } from '@/store/mutations-types';
import SimpleTable from '@/components/SimpleTable.vue'
import api from '@/api/api';
import VueApexCharts from 'vue-apexcharts';

export default defineComponent({
    name: 'DashSprintView',
    components: {
          apexchart: VueApexCharts,
          SimpleTable
    },

    data () {
        return {
            exibeFiltro: false,
            sprintFiltro: '',
            sprintFiltrada: '',
            periodoSprint: '',
            dsSprintFiltro: [],
            dsPlanejadas: [],
            dsEfetivadas: [],            
            columnsPlanejadas: [
                {
                  id: 1,
                  columnName: 'Status'  
                },
                {
                  id: 2,
                  columnName: 'Tarefas'  
                },
                {
                  id: 3,
                  columnName: '%'  
                }
            ],
            columnsEfetivado: [
                {
                  id: 1,
                  columnName: 'Tipo'  
                },
                {
                  id: 2,
                  columnName: 'Tarefas'  
                },
                {
                  id: 3,
                  columnName: '%'  
                }
            ]

        }
    },
    computed: {
        corLabel (){
            let cor
            if (this.$vuetify.theme.dark){
                cor = '#FFFFFF'
            } else {
                cor = '#000000'
            }
            return cor
        },
        optionsRadarPlanejadas () {
            return {
                chart: {
                    type: 'polarArea',                    
                    foreColor: this.corLabel,
                    toolbar: {
                        show: true                        
                    },                    
                }, 
                responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: "100%"
                        },
                        legend: {
                        position: 'bottom'
                        }
                    }
                }],
                legend: {
                    position: 'bottom'
                },
                title: {
                    text: 'Status Tarefas Planejadas',
                    align: 'center',
                    margin: 10,
                    offsetX: 0,
                    offsetY: 0,
                    floating: false,
                    style: {
                        fontSize:  '14px',
                        fontWeight:  'bold',                        
                        color:  this.corLabel
                    },
                },                
                labels: this.dsPlanejadas.map((x) => {
                        return  x.ULTIMO_STATUS
                }),
                fill: {
                    opacity: 1
                },
                theme: {
                    palette: 'palette1' // upto palette10
                }
            }
        },
        optionsRadialMeta () {
            return {
                chart: {    
                    type: 'radialBar',
                    toolbar: {
                        show: true
                    },                                                           
                },   
                responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: "100%"
                        },
                        legend: {
                        position: 'bottom'
                        }
                    }
                }],                   
                colors: this.corRadialMeta,
                plotOptions: {
                    radialBar: {
                    startAngle: -90,
                    endAngle: 90,
                    track: {
                        background: this.corLabel,
                        startAngle: -90,
                        endAngle: 90,
                    },
                    hollow: {                        
                        size: "30%"
                    },                    
                    dataLabels: {   
                        name: {
                            show: true,
                            fontSize: '16px',
                            fontFamily: undefined,
                            fontWeight: 600,
                            color: undefined,
                            offsetY: 15
                        },                                             
                        value: {
                            color: this.corLabel,
                            fontSize: "25px",
                            show: true,
                            offsetY: -25,
                        },
                        position: 'center'
                    }
                    }
                },                        
                labels: parseInt(this.sprintFiltrada.slice(7, 11)) >= 100 ? ['Meta: 80%'] : ['Meta: 65%'],
            }
        },  
        optionsDonutEfetivado(){
            return {
                chart: {
                    type: 'donut',
                    foreColor: this.corLabel,
                    toolbar: {
                        show: true
                    },  
                },
                stroke:{
                    show: false,
                },
                dataLabels: {
                    enabled: false,
                },
                legend: {
                    position: 'bottom'
                },
                responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: "100%"
                        },
                        legend: {
                            position: 'bottom'
                        }
                    }
                }],
                title: {
                    text: 'Tarefas Realizadas',
                    align: 'center',
                    margin: 10,
                    offsetX: 0,
                    offsetY: 0,
                    floating: false,
                    style: {
                        fontSize:  '14px',
                        fontWeight:  'bold',                        
                        color:  this.corLabel
                    },
                },                 
                theme: {
                    palette: 'palette1' // upto palette10
                },
                labels: this.dsEfetivadas ? this.dsEfetivadas.map(x => x.type) : '' ,             
            }
        },      
        radarPlanejadasSeries () {
            const ds = this.dsPlanejadas.map((x) => x.PERCENT)                                       
            return ds
        },
        radialMetaSeries () {
            const ds = this.dsPlanejadas.find((x) => x.ULTIMO_STATUS === 'Finalizada')                        
            if(!ds){
                return [0]
            } else {
                return [parseFloat(ds.PERCENT)]
            }

        },
        donutEfetivadoSeries () {
            if (!this.dsEfetivadas) {
                return [0]
            } else {
                const ds = this.dsEfetivadas.map((x) => parseFloat(x.PERCENT))                      
                return ds
            }

        },
        corRadialMeta() {
            let cor
            if (parseInt(this.sprintFiltrada.slice(7, 11)) >= 100) {
                switch (true) { 
                case (this.radialMetaSeries <= 40):
                     cor = ['rgb(255, 0, 0)'];
                     break;
                case (this.radialMetaSeries <= 60):
                     cor = ['rgb(255, 158, 0)'];
                     break;
                case (this.radialMetaSeries <= 79.9):
                     cor = ['rgb(251, 254, 0)'];
                     break;                     
                case (this.radialMetaSeries >= 80  && this.radialMetaSeries <= 90):
                     cor = ['rgb(0, 255, 0)'];
                     break;
                case (this.radialMetaSeries > 90):
                     cor = ['rgb(201, 0, 255)'];                              
                     break;
                }
            } else {
                switch (true) { 
                    
                    case (this.radialMetaSeries <= 20):
                         cor = ['rgb(255, 0, 0)'];
                         break;
                    case (this.radialMetaSeries <= 40):
                         cor = ['rgb(255, 158, 0)'];
                         break;
                    case (this.radialMetaSeries <= 64.9):
                         cor = ['rgb(251, 254, 0)'];
                         break;                     
                    case (this.radialMetaSeries <= 75):
                         cor = ['rgb(0, 255, 0)'];
                         break;
                    case (this.radialMetaSeries > 75):
                         cor = ['rgb(201, 0, 255)'];                              
                         break;
                }
            }            
            return cor
        },
        totalTarefas(){  
            let prepareRealizadas =  this.dsPlanejadas.find((x) => x.ULTIMO_STATUS === 'Finalizada')
            if (typeof(prepareRealizadas) === 'undefined')  prepareRealizadas = [{}] 
                       
            const totalPlanejadas = this.dsPlanejadas.reduce((x, y) => parseInt(x) + parseInt(y.TASKS), 0);
            const totalRealizadas = 'TASKS' in prepareRealizadas ? this.dsPlanejadas.find((x) => x.ULTIMO_STATUS === 'Finalizada').TASKS : 0              
            return {
                totalPlanejadas,
                totalRealizadas
            }
        },     
        dsTables() {
            const totalPlanejadas = this.dsPlanejadas.reduce((x, y) => parseInt(x) + parseInt(y.TASKS), 0);            
            const totalRealizadas = this.dsEfetivadas.reduce((x, y) => parseInt(x) + parseInt(y.TASKS), 0);
            let tablePlanejada = this.dsPlanejadas.map((x) => x)            
            let tableRealizada = this.dsEfetivadas.map((x) => x)            
            tablePlanejada.push({
                TASKS: totalPlanejadas,
                ULTIMO_STATUS: 'TOTAL',
                PERCENT: '100%',
                vTexto: 'TOTAL',
                COR: '',
                descsprint: ''
            })
            tableRealizada.push({
                TASKS: totalRealizadas,
                type: 'TOTAL',
                PERCENT: '100%',
                vDesc: 'TOTAL',
                COR: ''                
            })                       
            return {
                tablePlanejada,
                tableRealizada
            }
        }  
    },
    methods: {
        changeExibeFiltro: function () {
            this.exibeFiltro = !this.exibeFiltro
        },
        changeSprint: async function() {            
           if (this.sprintFiltro){
               this.sprintFiltrada = this.sprintFiltro;
               const idSprint = this.dsSprintFiltro.find((x) => x.DESCSPRINT === this.sprintFiltro).CODIGOSPRINT;
               const response = await api.post('/dash/planejadoefetivado', {idSprint: idSprint});
               this.periodoSprint = response.data.periodoSprint[0].dtFim;
               this.dsPlanejadas = response.data.previsto;
               this.dsEfetivadas = response.data.efetivado;
               this.exibeFiltro = !this.exibeFiltro;
               store.commit(M_MENU, `DashBoard Sprints | ${this.periodoSprint}`);
           }             
        },        
    },
    async mounted() {
        if (this.dsPlanejadas.length === 0 || this.dsEfetivadas.length === 0){
            const response = await api.post('/dash/planejadoefetivado');
            this.periodoSprint = response.data.periodoSprint[0].dtFim;            

            this.dsPlanejadas = response.data.previsto;
            this.dsEfetivadas = response.data.efetivado;    
            this.sprintFiltrada = response.data.sprintAtual.DESCSPRINT       
        }
        if (this.dsSprintFiltro.length === 0){
            const response = await api.get('/sprints');
            this.dsSprintFiltro = response.data;
        }
        store.commit(M_MENU, `DashBoard Sprints | ${this.periodoSprint}`);
    },
})

