


import api from '@/api/api';
import Grid from '@/components/Grid.vue';
import ModalCrud from '@/components/ModalCrud.vue';
import { store } from '@/store';
import { M_MENU } from '@/store/mutations-types';
import { columns } from '@/utils/columns';
import { defineComponent } from 'vue';

export default defineComponent({
	name: 'Regioes',
	data() {
		return {
			loadingGrid: false,
			valid: true,
			isLoading: false,
			textoModal: '',
			modalAberto: false,
			regioes: [],
			descricaoRules: [
				v => !!v || 'Descrição precisa ser preenchida.',
			],
			regiao: {
				id: 0,
				descricao: '',
				id_status: false
			},
			columns: columns,
			gridItemsActions: [
				{
					label: 'Editar',
					action: 'edit',
					icon: 'mdi-pencil',
					color: 'primary',
					display: true,
					loading: false
				}
			]
		}
	},
	components: {
		Grid,
		ModalCrud
	},
	methods: {
		newRegion() {
			this.modalAberto = true
			this.regiao.id = 0
			this.regiao.descricao = ''
			this.regiao.id_status = true
			this.textoModal = 'Nova região'
		},
		async editRegion(item) {
			this.textoModal = 'Editar Região'
			const response = await api.get(`/region/${item.ID}`)
			const { ID, DESCRICAO, ID_STATUS } = response.data
			this.regiao.id = ID
			this.regiao.descricao = DESCRICAO,
				this.regiao.id_status = ID_STATUS === 'A' ? true : false
			this.modalAberto = true
		},
		async saveRegion() {
			try {
				if (this.$refs.form.validate()) {
					this.isLoading = true;
					const [metodo, mensagem] = this.regiao.id ? ['put', 'alterada'] : ['post', 'criada'];
					const operation = await api[metodo]('/region', this.regiao);
					const response = await api.get('/region');
					this.regioes = response.data;
					this.$toast.success(`Região ${mensagem} com sucesso!`);
					this.modalAberto = false
				} else {
					this.$toast.warning('Formulário inválido! Verifique os dados.');
				}
			} catch (err) {
				this.$toast.error('Falha ao comunicar com o banco de dados!');
			}
			finally {
				this.isLoading = false;
			}
		},
		handleGridItemsActions: function (action: 'edit', item) {
			switch (action) {
				case 'edit':
					this.editRegion(item);
					break;
				default:
					this.$toast.error('Opção de ação inválida!');
			};
		},
	},
	async mounted() {
		this.loadingGrid = true;
		if (this.regioes.length === 0) {
			const response = await api.get("/region");
			this.regioes = response.data;
		}
		store.commit(M_MENU, "Regiões");
		this.loadingGrid = false;
	},
})

