import { INotify } from '@/types/INotify'
import Vue from 'vue'
import Vuex from 'vuex'
import { login} from './modules/login'
import { M_MENU, M_NOTIFY, M_SHOWNAV } from './mutations-types'
import { infoUser, StateUser } from './modules/infoUser'

Vue.use(Vuex)

export interface StateGlobal {
  notifications: INotify[],
  showNav: boolean,
  currentMenu: string,
  loading: boolean,
  canSeeHeader: boolean
}

export const store = new Vuex.Store({
  state: {    
    notifications: [],
    showNav: false,
    currentMenu: '',
    loading: false,
    canSeeHeader: true
  },
  mutations: {    
    [M_NOTIFY](state, notifications: INotify) {
      notifications.id = new Date().getTime();
      state.notifications.push(notifications);      
      setTimeout(() => {
          state.notifications = state.notifications.filter(x => x.id != notifications.id)
      }, 3000)
    },
    [M_SHOWNAV](state, showNav){      
      store.state.showNav = !store.state.showNav
    },
    [M_MENU](state, menuAcessado: string){
      state.currentMenu = menuAcessado
    }
  },
  modules: {
    login,    
    infoUser
  }
})
