import { Acesso } from "@/types/IAcessos";

export const findMenus = (menus: Acesso[], rota: string): boolean => {
  if (rota === '/home') {
    return true;
  }

  const foundMenus = menus.filter(menu => {
    if (menu.items && menu.items.length > 0) {
      return menu.items.find(item => rota.includes(item.route));
    }
    return false;
  });

  return foundMenus.length > 0;
};
