

import { store } from '@/store';
import { M_MENU } from '@/store/mutations-types';
import { defineComponent } from 'vue';
import NFSeList from './NFSeList.vue';
import CargaDados from '../CargaDados/CargaDados.vue';


export default defineComponent({
    name: "NFSeExpress",
    data() {
        return {
            tabTipo: null,
            tabStatus: null,
            loadingGrid: false,
            showModal: false
        };
    },
    methods: {
        closeModalCarga() {
            this.showModal = false;
        }
    },
    async mounted() {
        this.loadingGrid = true;
        store.commit(M_MENU, "NFS-e Express");
        this.loadingGrid = false;
    },
    components: {
        NFSeList,
        CargaDados
    }
})

