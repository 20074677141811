<template>
    <v-container fluid full-width style="padding: 0px 12px 0px 12px;">
        <v-toolbar flat style="background-color: transparent;">
            <v-btn outlined color="darken-2"
                :disabled="dateInfo.actualMonth?.getMonth() === dateInfo.currentMonthExt?.getMonth()"
                @click="setCurrentMonth()">
                Mês atual
            </v-btn>
            <v-btn fab text small color="darken-2" @click="updateMonth(false)">
                <v-icon small>
                    mdi-chevron-left
                </v-icon>
            </v-btn>
            <v-btn fab text small color="darken-2" @click="updateMonth(true)">
                <v-icon small>
                    mdi-chevron-right
                </v-icon>
            </v-btn>
            <v-toolbar-title v-if="dateInfo.title">{{ dateInfo.title }}</v-toolbar-title>
        </v-toolbar>
        <CardsTabResumo :saldo-banco="dataset.overviewTotalizer?.saldoBanco ?? 'R$ 0,00'"
            :saldo-caixa="dataset.overviewTotalizer?.saldoCaixa ?? 'R$ 0,00'"
            :saldo-geral="dataset.overviewTotalizer?.saldoGeral ?? 'R$ 0,00'"
            :valor-pagar-geral="dataset.overviewTotalizer?.valorPagarGeral ?? 'R$ 0,00'"
            :valor-receber-geral="dataset.overviewTotalizer?.valorReceberGeral ?? 'R$ 0,00'" />
        <v-row cols="12">
            <v-col md="4">
                <v-card outlined>
                    <v-list-item three-line>
                        <v-list-item-content>
                            <div class="text-overline mb-4">
                                Valor Gerado
                            </div>
                            <v-list-item-title class="text-h5 mb-1">
                                R$ {{ dataset.overviewTotalizer?.valorGeradoCC }}
                            </v-list-item-title>
                        </v-list-item-content>

                        <v-list-item-avatar tile size="80">
                            <v-icon large color="#2196F3">mdi-cash-fast</v-icon>
                        </v-list-item-avatar>
                    </v-list-item>
                </v-card>
            </v-col>
            <v-col md="4">
                <v-card outlined>
                    <v-list-item three-line>
                        <v-list-item-content>
                            <div class="text-overline mb-4">
                                Valor Recebido
                            </div>
                            <v-list-item-title class="text-h5 mb-1">
                                R$ {{ dataset.overviewTotalizer?.valorRecebidoCC }}
                                <span v-if="!this.isMobile"> | {{ dataset.overviewTotalizer?.percentualRecebidoCC
                                    }}%</span>
                            </v-list-item-title>
                            <v-list-item-subtitle v-if="this.isMobile" class="font-weight-bold">
                                {{ dataset.overviewTotalizer?.percentualRecebidoCC }}%
                            </v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-avatar tile size="80">
                            <v-icon large color="#4CAF50">mdi-cash-plus</v-icon>
                        </v-list-item-avatar>
                    </v-list-item>
                </v-card>
            </v-col>
            <v-col md="4">
                <v-card outlined>
                    <v-list-item three-line>
                        <v-list-item-content>
                            <div class="text-overline mb-4">
                                Valor à receber
                            </div>
                            <v-list-item-title class="text-h5 mb-1">
                                R$ {{ dataset.overviewTotalizer?.valorReceberCC }}
                                <span v-if="!this.isMobile"> | {{ dataset.overviewTotalizer?.percentualReceberCC
                                    }}%</span>

                            </v-list-item-title>
                            <v-list-item-subtitle v-if="this.isMobile" class="font-weight-bold">
                                {{ dataset.overviewTotalizer?.percentualReceberCC }}%
                            </v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-avatar tile size="80">
                            <v-icon large color="#FEB019">mdi-cash-refund</v-icon>
                        </v-list-item-avatar>
                    </v-list-item>
                </v-card>
            </v-col>
        </v-row>
        <v-row cols="12">
            <v-col md="4" sm="12" xs="12" cols="12">
                <v-card outlined style="height: 500px;">
                    <v-chart :option="dueDateAnalyze" theme="dark"></v-chart>
                </v-card>
                <div style="margin: 10px;"></div>
                <v-card outlined style="height: 500px;">
                    <div style="display: flex; justify-content: center; align-items: center;">
                        <v-radio-group v-model="dataFilter.idOptionCentroCusto" row>
                            <v-radio label="Recebido" :value="1"></v-radio>
                            <v-radio label="À receber" :value="2"></v-radio>
                        </v-radio-group>
                    </div>
                    <div style="height: 400px;">
                        <v-chart :key="dataFilter.idOptionCentroCusto" :option="costCenter" theme="dark" />
                    </div>
                </v-card>
            </v-col>
            <v-col md="8" sm="12" xs="12" cols="12">
                <v-card outlined  class="pa-2">
                    <v-row justify="center">
                        <v-col md="auto">
                            <v-btn class="ma-2" color="#2196F3" style="width: 145px;"
                                @click="dashCompanyLabels.valorGerado = !dashCompanyLabels.valorGerado">
                                Valor Gerado
                            </v-btn>
                        </v-col>
                        <v-col md="auto">
                            <v-btn class="ma-2" color="#4CAF50" style="width: 145px;"
                                @click="dashCompanyLabels.valorRecebido = !dashCompanyLabels.valorRecebido">
                                Valor Recebido
                            </v-btn>
                        </v-col>
                        <v-col md="auto">
                            <v-btn class="ma-2" color="#FEB019" style="width: 145px;"
                                @click="dashCompanyLabels.valorAReceber = !dashCompanyLabels.valorAReceber">
                                Valor À Receber
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-card outlined class="my-1" elevation="0" v-for="(empresa, indexEmpresa) in dataset.totalizerByCompay"
                        :key="indexEmpresa">
                        <div style="font-size: 1.10rem; margin-left: 1.8rem; margin-bottom: -3rem;">{{ empresa.company
                            }}</div>
                        <apexchart type="bar" :options="dashByCompanyApex[indexEmpresa].chartOptions" height="200px"
                            :series="dashByCompanyApex[indexEmpresa].series">
                        </apexchart>
                    </v-card>
                </v-card>
            </v-col>
            <v-dialog v-model="modalDrilDown.visible" :fullscreen="this.isMobile" transition="dialog-bottom-transition">
                <v-toolbar style="height: 7%;" elevation="1">
                    <v-toolbar-title>{{ dataFilter.period.slice(4, 7) + '/' +
                        dataFilter.period.slice(0, 4) }} - {{ dataFilter.company }}</v-toolbar-title>
                    <v-spacer>
                    </v-spacer>
                    <v-btn dark small color="error" @click="closeModalDrillDown()">
                        <v-icon dark>
                            mdi-close
                        </v-icon>
                    </v-btn>
                </v-toolbar>
                <v-data-table :headers="modalDrilDown.columns" :loading="isLoading" :items="modalDrilDown.dataset"
                    :sort-desc="true" class="elevation-3 gridVue"
                    :style="!this.isMobile ? 'height: 93%;' : 'height: 100%;'">
                </v-data-table>
            </v-dialog>
        </v-row>
    </v-container>
</template>

<script>
import CardsTabResumo from '@/components/DashFinanceiro/CardsTabResumo.vue';
import { dateFunctions } from '@/utils/dateFunctions'
import { defineComponent } from 'vue';
import { use } from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { PieChart, BarChart } from 'echarts/charts';
import {
    TitleComponent,
    TooltipComponent,
    LegendComponent,
    GridComponent
} from 'echarts/components';
import VChart from 'vue-echarts';
import api from '@/api/api';
import VueApexCharts from 'vue-apexcharts';

use([
    CanvasRenderer,
    PieChart,
    BarChart,
    TitleComponent,
    TooltipComponent,
    LegendComponent,
    GridComponent
]);
export default defineComponent({
    name: 'TabResumoMensal',
    data() {
        return {
            isLoading: false,
            dataFilter: {
                idDrillDown: 1,
                period: '',
                company: '',
                idOptionCentroCusto: 1,
                drillDownOption: ''
            },
            dateInfo: {
                title: '',
                currentMonthExt: null,
                actualMonth: null
            },
            dataset: [],
            modalDrilDown: {
                visible: false,
                dataset: [],
                columns: []
            },
            dashCompanyLabels: {
                valorGerado: true,
                valorRecebido: true,
                valorAReceber: true
            }
        }
    },
    methods: {
        convertPeriodToNumber(period) {
            return period.getFullYear() + (period.getMonth() + 1).toString().padStart(2, '0')
        },
        mountMonth(inicialize) {
            try {
                this.$data.dateInfo.actualMonth = new Date();
                if (inicialize) this.$data.dateInfo.currentMonthExt = new Date();
                this.$data.dateInfo.title = this.dateFunctions.formatYearMonth(this.$data.dateInfo.currentMonthExt.getMonth(), this.$data.dateInfo.currentMonthExt.getFullYear())
            } catch (error) {

                this.$toast.error('Falha ao carregar as datas!')
            }
        },
        async updateMonth(next) {
            let actualMonth = this.$data.dateInfo.currentMonthExt;
            if (next) {
                if (actualMonth.getMonth() === 11) {
                    this.$data.dateInfo.currentMonthExt.setMonth(0);
                    this.$data.dateInfo.currentMonthExt.setFullYear(this.$data.dateInfo.currentMonthExt.getFullYear() + 1);
                } else {
                    this.$data.dateInfo.currentMonthExt.setMonth(this.$data.dateInfo.currentMonthExt.getMonth() + 1);
                }
            } else {
                if (actualMonth.getMonth() === 0) {
                    this.$data.dateInfo.currentMonthExt.setMonth(11);
                    this.$data.dateInfo.currentMonthExt.setFullYear(this.$data.dateInfo.currentMonthExt.getFullYear() - 1);
                } else {
                    this.$data.dateInfo.currentMonthExt.setMonth(this.$data.dateInfo.currentMonthExt.getMonth() - 1);
                }
            }
            this.mountMonth(false);
        },
        setCurrentMonth() {
            this.$data.dateInfo.currentMonthExt = this.$data.dateInfo.actualMonth;
            this.mountMonth();
        },
        async loadData() {
            try {
                this.$store.state.loading = true;
                this.dataFilter.period = this.convertPeriodToNumber(this.$data.dateInfo.currentMonthExt)
                const { data } = await api.post('/dash/boletos', this.$data.dataFilter);
                this.dataset = data;
            } catch (error) {
                this.$toast.error('Falha ao carregar os dados!');
            } finally {
                this.$store.state.loading = false;
            }
        },
        async handleModalDrillDown(typeOfModal, company) {
            try {
                this.$store.state.loading = true;

                this.dataFilter.drillDownOption = typeOfModal;
                this.dataFilter.company = company;

                const { data } = await api.post('/dash/boletos/drill-down', this.dataFilter);

                this.modalDrilDown.dataset = data.dataset;
                this.modalDrilDown.columns = data.columns;

                this.modalDrilDown.visible = true;
            } catch (error) {
                this.$toast.error('Falha ao carregar os dados!');
            } finally {
                this.$store.state.loading = false;
            }
        },
        closeModalDrillDown() {
            this.dataFilter.drillDownOption = '';
            this.dataFilter.company = '';
            this.modalDrilDown.visible = false;
            this.modalDrilDown.dataset = [];
            this.modalDrilDown.columns = [];
        },
    },
    watch: {
        'dateInfo.title': async function (newValue) {
            await this.loadData();
        },
        'dataFilter.idOptionCentroCusto': async function (newValue) {
            await this.loadData();
        },
    },
    computed: {
        dueDateAnalyze() {
            let valueSum = 0;
            const prepareData = [];
            if (Number(this.dataset?.vencidasVencendoHoje?.vencidas) > 0) {
                prepareData.push({
                    value: Number(this.dataset.vencidasVencendoHoje.vencidas),
                    name: 'Vencidas'
                })
                valueSum += Number(this.dataset.vencidasVencendoHoje.vencidas);
            }
            if (Number(this.dataset?.vencidasVencendoHoje?.vencendoHoje) > 0) {
                prepareData.push({
                    value: Number(this.dataset.vencidasVencendoHoje.vencendoHoje),
                    name: 'Vencendo hoje'
                });
                valueSum += Number(this.dataset.vencidasVencendoHoje.vencendoHoje);

            }
            if (Number(this.dataset?.vencidasVencendoHoje?.aVencer) > 0) {
                prepareData.push({
                    value: Number(this.dataset?.vencidasVencendoHoje?.aVencer),
                    name: 'Vencendo'
                });
                valueSum += Number(this.dataset?.vencidasVencendoHoje?.aVencer);
            }
            return {
                title: {
                    text: 'Análise de Vencimento',
                    left: 'center',
                    textStyle: {
                        color: this.$vuetify.theme.dark ? '#FFFFFF' : '#000000',
                        fontWeight: 'bold'
                    },
                    subtext: 'Total: ' + 'R$ ' + valueSum.toLocaleString('pt-br', {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2
                    }),
                    subtextStyle: {
                        color: this.$vuetify.theme.dark ? '#FFFFFF' : '#000000',
                        align: 'center',
                        verticalAlign: 'top'
                    }
                },
                tooltip: {
                    trigger: 'item'
                },
                legend: {
                    orient: 'vertical',
                    left: 'left',
                    textStyle: {
                        color: this.$vuetify.theme.dark ? '#FFFFFF' : '#000000',
                        fontWeight: 'bold'
                    },
                    top: 30
                },
                backgroundColor: 'transparent',
                grid: {
                    show: false,
                    top: 80,
                },
                textStyle: {
                    color: this.$vuetify.theme.dark ? '#FFFFFF' : '#000000',
                    fontWeight: 'bold'
                },
                series: prepareData.length ? [
                    {
                        name: 'Valores',
                        type: 'pie',
                        radius: '50%',
                        data: prepareData,
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        },
                        color: ['#2196F3', '#4CAF50', '#FEB019'],
                        tooltip: {
                            valueFormatter: function (val) {
                                return 'R$ ' + val.toLocaleString('pt-br', {
                                    maximumFractionDigits: 2,
                                    minimumFractionDigits: 2
                                })
                            }
                        }
                    }
                ] : [],
                label: {
                    position: 'inside',
                    formatter: '{per|{d}%}  ',
                    fontSize: 18,
                    rich: {
                        per: {
                            color: '#fff',
                            backgroundColor: '#4C5058',
                            padding: [3, 4],
                            borderRadius: 4,
                            fontWeight: 'bold'
                        }
                    }
                },

            }
        },
        dashByCompanyApex() {
            return this.dataset?.totalizerByCompay?.length ? this.dataset.totalizerByCompay.map(x => {
                const prepareSeries = [];
                if (this.dashCompanyLabels.valorGerado) {
                    prepareSeries.push({
                        name: 'Valor Gerado',
                        data: [Number(x.valorGerado)],
                        color: '#2196F3',
                        hidden: false,
                    });
                }
                if (this.dashCompanyLabels.valorRecebido) {
                    prepareSeries.push({
                        name: 'Valor Recebido',
                        data: [Number(x.valorRecebido)],
                        color: '#4CAF50'
                    });
                }
                if (this.dashCompanyLabels.valorAReceber) {
                    prepareSeries.push({
                        name: 'Valor à Receber',
                        data: [Number(x.valorAReceber)],
                        color: '#FEB019'
                    });
                }
                return {
                    chartOptions: {
                        grid: {
                            show: false
                        },
                        chart: {
                            type: 'bar',
                            foreColor: this.corLabel,
                            toolbar: {
                                show: false
                            },
                            events: {
                                dataPointSelection: async (event, chartContext, config) => {
                                    await this.handleModalDrillDown('dash-company', config.w.config.xaxis.categories[config.dataPointIndex])
                                }
                            }
                        },
                        tooltip: {
                            enabled: !this.$vuetify.breakpoint.mobile,
                            y: {
                                formatter: (value) => {
                                    return value.toLocaleString('pt-br', {
                                        maximumFractionDigits: 2,
                                        minimumFractionDigits: 2
                                    })
                                }
                            }
                        },
                        responsive: [{
                            breakpoint: 480,
                            options: {
                                chart: {
                                    width: "100%",
                                },
                                yaxis: {
                                    labels: {
                                        show: false
                                    },
                                    show: false
                                }
                            }
                        }],
                        yaxis: {
                            labels: {
                                show: false,
                            },
                            show: false
                        },
                        xaxis: {
                            categories: [x.company],
                            labels: {
                                show: false,
                                trim: true,
                            },
                            axisBorder: {
                                show: false
                            },
                            axisTicks: {
                                show: false
                            },
                        },
                        legend: {
                            show: false
                        },
                        dataLabels: {
                            enabled: true,
                            style: {
                                fontSize: '0.9rem',
                                fontWeight: 'bold'
                            },
                            offsetY: 4,
                            offsetX: 30,
                            background: {
                                enabled: true,
                                foreColor: '#546E7A',
                                padding: 4,
                                borderRadius: 2,
                                borderWidth: 1,
                                borderColor: '#fff',
                                opacity: 0.9,
                                dropShadow: {
                                    enabled: false,
                                    top: 1,
                                    left: 1,
                                    blur: 1,
                                    color: '#000',
                                    opacity: 0.45
                                }
                            },
                            formatter: (value, opts) => {
                                return value.toLocaleString('pt-br', {
                                    maximumFractionDigits: 2,
                                    minimumFractionDigits: 2
                                })
                            }
                        },
                        plotOptions: {
                            bar: {
                                horizontal: true,
                                borderRadius: 2,
                                borderRadiusApplication: 'around',
                                rangeBarOverlap: false,
                                colors: {
                                    backgroundBarColors: ['#546E7A'],
                                    backgroundBarOpacity: 0.25,
                                    backgroundBarRadius: 2,
                                },
                            },
                        }
                    },
                    series: prepareSeries
                }
            }) : null
        },
        costCenter() {
            return {
                title: {
                    text: 'Recebido por Centro de Custos',
                    left: 'center',
                    textStyle: {
                        color: this.$vuetify.theme.dark ? '#FFFFFF' : '#000000',
                        fontWeight: 'bold'
                    },
                    subtextStyle: {
                        color: this.$vuetify.theme.dark ? '#FFFFFF' : '#000000',
                        align: 'center',
                        verticalAlign: 'top'
                    }
                },
                tooltip: {
                    trigger: 'item'
                },
                legend: {
                    orient: 'horizontal',
                    top: 'bottom',
                    textStyle: {
                        color: this.$vuetify.theme.dark ? '#FFFFFF' : '#000000',
                        fontWeight: 'bold'
                    }
                },
                backgroundColor: 'transparent',
                textStyle: {
                    color: this.$vuetify.theme.dark ? '#FFFFFF' : '#000000',
                    fontWeight: 'bold'
                },
                series: [
                    {
                        name: 'Valores',
                        type: 'pie',
                        radius: '50%',
                        data: this.dataset.receveidByCenter?.length ? this.dataset.receveidByCenter.map(x => {
                            if (Number(x.valorRecebido)) {
                                return {
                                    value: Number(x.valorRecebido),
                                    name: x.tipoConta
                                }
                            }

                        }) : [],
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        },
                        tooltip: {
                            valueFormatter: function (val) {
                                return 'R$ ' + val.toLocaleString('pt-br', {
                                    maximumFractionDigits: 2,
                                    minimumFractionDigits: 2
                                })
                            }
                        }
                    }
                ],
                label: {
                    position: 'inside',
                    formatter: '{per|{d}%}  ',
                    fontSize: 18,
                    rich: {
                        per: {
                            color: '#fff',
                            backgroundColor: '#4C5058',
                            padding: [3, 4],
                            borderRadius: 4,
                            fontWeight: 'bold'
                        }
                    }
                },
            }
        }
    },
    components: {
        CardsTabResumo,
        VChart,
        apexchart: VueApexCharts
    },
    async mounted() {
        this.mountMonth(true);
        await this.loadData();
    },
    setup() {
        return {
            dateFunctions
        }
    }
})


</script>