

import api from '@/api/api';
import ModalCrud from '@/components/ModalCrud.vue';
import { columns } from '@/utils/columns';
import { dateFunctions } from '@/utils/dateFunctions';
import { formsValidations } from '@/utils/formsValidations';
import { defineComponent } from 'vue';

export default defineComponent({
    name: "CargaDados",
    data() {
        return {
            items: [],
            loading: false,
            autoRefresh: null,
            modalAberto: false,
            modalLoading: false,
            valid: false,
            dataForRequest: {
                mesAno: ''
            }
        };
    },
    methods: {
        openModal() {
            const currentDate = dateFunctions.getDateNow();
            this.dataForRequest = {
                id: 0,
                mesAno: currentDate.slice(0, 7)
            },
                this.modalAberto = true;
        },
        async requestUpdate() {
            try {
                this.modalLoading = true;
                this.dataForRequest.mesAno = this.dataForRequest.mesAno.slice(5, 7) + "/" + this.dataForRequest.mesAno.slice(0, 4);
                const response = await api.post('/load-data/request', this.dataForRequest);
                this.dataForRequest = {
                    mesAno: ''
                },
                    this.modalAberto = false
                this.$toast.success("Solicitação de carga enviada com sucesso!");
                await this.updateData();
            } catch (err) {
                console.log(err);
                this.$toast.error("Falha ao comunicar com o banco de dados!");
            } finally {
                this.modalLoading = false;
            }
        },
        async updateData() {
            try {
                this.loading = true;
                const response = await api.get("/load-data/data");
                this.items = response.data;
            }
            catch (err) {
                console.log(err);
                this.$toast.error("Falha ao comunicar com o banco de dados!");
            }
            finally {
                this.loading = false;
            }
        },
        closeModalCarga() {
            this.$emit("closeModalCarga");
        }
    },
    props: {
        opened: {
            type: Boolean,
            required: true
        },
    },
    computed: {
        show() {
            return this.opened;
        }
    },
    beforeDestroy() {
        clearInterval(this.autoRefresh);
    },
    async mounted() {
        this.autoRefresh = setInterval(async () => {
            await this.updateData();
        }, 5000);
        await this.updateData();
    },
    setup() {
        return {
            columns,
            formsValidations
        };
    },
    components: { ModalCrud }
})


