

import { store } from '@/store';
import { LOGIN } from '@/store/actions-types';
import { defineComponent } from 'vue';
import { formsValidations } from '@/utils/formsValidations';
import router from '@/router/index';
import Cookie from 'js-cookie';

export default defineComponent({
    name: 'LoginView',
    data: () => ({
        isLoading: false,
        valid: true,
        dataLogin: {
            email: '',
            password: ''
        }
    }),
    methods: {
        async login() {
            const valid = await this.$refs.form.validate();
            if (!valid) throw this.$toast.warning('Formulário inválido! Verifique os dados.');
            try {
                this.isLoading = true;
                const response = await store.dispatch(LOGIN, this.dataLogin);
                Cookie.set('csnoteweb', response.data.token, { expires: 365 });
                let nameUser = response.data.name.split(' ');                
                await this.$router.push('/home');                
                this.$toast.success(`É bom ter você por aqui ${nameUser[0]}!`);
            } catch (err) {                   
                this.$toast.error('E-mail ou senha incorretos.');
                console.log(err)
            } finally {
                this.isLoading = false
            }
        },
    },
    setup () {
        return {
            formsValidations
        }
    }

})

