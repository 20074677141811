

import api from '@/api/api';
import { columns } from '@/utils/columns';
import { defineComponent } from 'vue';

export default defineComponent({
	name: 'ModalCrud',
	data() {
		return {
			search: '',
			checkedContas: [],
			loading: false,
			observacao: '',
		}
	},
	methods: {
		async save() {
			try {
				this.loading = true;
				let contas = this.checkedContas.map((x) => x.ID_REF_PARCELA_CONTA)
				await api.put('/nfse', {
					contas,
					idStatus: 1,
					observacao: this.observacao,
					tipoEmissao: this.tipoEmissao,
				});
				this.$emit('updateData');
				this.$emit('closeModal');
				this.$toast.success("Documentos processados!");
			}
			catch (err) {
				this.$toast.error("Falha ao comunicar com o banco de dados!");
			}
			finally {
				this.loading = false;
			}
		},
		async cancel() {
			try {
				this.loading = true;
				let contas = this.checkedContas.map((x) => x.ID_REF_PARCELA_CONTA)
				await api.delete('/nfse', {
					data: {
						ids: contas
					}
				});
				this.$emit('updateData');
				this.$emit('closeModal');
				this.$toast.success("Documentos excluídos!");
			} catch (error) {
				this.$toast.error("Falha ao comunicar com o banco de dados!");
			}
			finally {
				this.loading = false;
			}
		},
		removeConta(item) {
			this.checkedContas = this.checkedContas.filter((x) => x.ID_REF_PARCELA_CONTA != item.ID_REF_PARCELA_CONTA);
		},
		closeModal() {
			this.$emit('closeModal');
		}
	},
	props: {
		contas: {
			type: [],
			required: true
		},
		operation: {
			type: String,
			required: false
		},
		tipoEmissao: {
			type: String,
			required: true
		},
		opened: {
			type: Boolean,
			required: true
		},
	},
	computed: {
		show() {
			return this.opened
		}
	},
	async mounted() {
		try {
			this.loading = true;
			const response = await api.post('/nfse', {
				contasForSend: this.contas.map((x) => x.ID_REF_PARCELA_CONTA)
			});
			this.checkedContas = response.data;
			this.items = response.data;
		}
		catch (err) {
			console.log(err);
			this.$toast.error("Falha ao comunicar com o banco de dados!");
		}
		finally {
			this.loading = false;
		}
	},
	setup() {
		return {
			columns
		}
	}
})


