

import api from '@/api/api';
import { defineComponent } from 'vue';
import ModalUserTasks from './ModalUserTasks.vue';

export default defineComponent({
    name: 'DrawerUserTasks',
    components: {
        ModalUserTasks
    },
    data() {
        return {
            drawer: true,
            selectedItem: 0,
            items: [
                { text: 'Ativas', icon: 'mdi-clock' },
                { text: 'Inativas', icon: 'mdi-account' }
            ],
            filterData: {
                dataset: [],
                selectedId: this.$store.state.infoUser.current.id
            },
            dataset: [],
            filterDataset: "",
            modalCrudVisible: false,
            modalCrudOperation: 'post',
            modalCrud: {
                visible: false,
                operation: 'post',
                id: null
            }
        }
    },
    watch: {
        'selectedItem': async function (newValue, oldValue) {
            await this.loadUserTasks();
        },
        'filterData.selectedId': async function (newValue, oldValue) {
            await this.loadUserTasks();
        }
    },
    methods: {
        async loadUserTasks() {
            try {
                this.$store.state.loading = true;
                this.filterData.dataset = [];
                const selectedUser = !this.filterData.selectedId ? this.$store.state.infoUser.current.id : this.filterData.selectedId;
                const typeOfRequest = this.selectedItem === 0 ? 'open' : 'closed';

                const response = await api.get(`/user-task?request=grid&id=${selectedUser}&typeOf=${typeOfRequest}`);
                this.filterData.dataset = response.data.users;
                this.dataset = response.data.userTasks;
            } catch (error) {
                this.$toast.error('Falha ao carregar os dados!');
            } finally {
                this.$store.state.loading = false;
            }
        },
        async handleSavedModal() {
            await this.loadUserTasks();
            this.modalCrud.visible = false;
        },
        async handleCloseModal() {
            this.modalCrud.visible = false;
        },
        handleOpenModalCrud(operation: string, id?: number) {
            this.modalCrud.visible = true;
            this.modalCrud.operation = operation;
            this.modalCrud.id = id;
        },
        async activeInactiveUserTask(id: number) {
            await api.patch(`/user-task/${id}`);
            await this.loadUserTasks();
        },
        closeModalOnEsc(e){
            if (e.key === 'Escape') {            
                this.$emit('closeModal')
            }        
        }
    },
    computed: {
        filteredDataset() {
            return this.dataset.filter((x) => x.DESCRICAOTAREFA.toLowerCase().includes(this.filterDataset.toLowerCase()))
        }
    },
    async mounted() {
        try {
            this.$store.state.loading = true;
            const response = await api.get(`/user-task?request=grid&id=${this.$store.state.infoUser.current.id}&typeOf=open`);
            this.filterData.dataset = response.data.users;
            this.dataset = response.data.userTasks;
            window.addEventListener('keydown', (e) => this.closeModalOnEsc(e));
        } catch (error) {
            this.$toast.error('Falha ao carregar os dados!');
        } finally {
            this.$store.state.loading = false;
        }
    },
    destroyed(){
        window.removeEventListener('keydown', (e) => this.closeModalOnEsc(e))
    }
})


