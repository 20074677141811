export const formsValidations = {
    fieldRequired: [
        v => !!v || 'Esse campo precisa ser preenchido.'
    ],
	valueRequired: [
		v => (!!v && !(Number(v.replaceAll('.', '').replaceAll(',', '.')) <= 0)) || 'Esse campo não pode ficar zerado.',
		v => !!v || 'Esse campo precisa ser preenchido.'
	],
    lengthRequired: (length: number) => {
        return v => (v && v.length < length) || 'Descrição não pode ter mais que 50 caracteres.'
    },
    requiredAndLength: (length: number) => {
        return [
            v => !!v || 'Descrição precisa ser preenchida.',
            v => (v && v.length < 50) || 'Descrição não pode ter mais que 50 caracteres.'
        ]        
    },
    emailRequiredAndValidation: () => {
        return [
            v => !!v || 'E-mail precisa ser preenchido.',
            v => /.+@.+\..+/.test(v) || 'E-mail precisa ser válido!',
        ]
    }

}