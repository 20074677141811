import api from "@/api/api";
import { StateGlobal } from "@/store";
import { LOGIN } from "@/store/actions-types";
import {IAuth} from "@/types/IAuth";
import { Module } from "vuex";

export interface StateAtuh {
    authUser: IAuth;
}


export const login: Module<StateAtuh, StateGlobal> = {
    actions: {
        [LOGIN](ctx, {email, password}: IAuth){
            return api.post('/auth', {
                email, 
                password
            })
        }
    }

}


