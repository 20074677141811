

import { defineComponent, PropType } from 'vue';
import { IGridActions } from '@/types/IGridActions';

export default defineComponent({
	name: 'Grid',
	data() {
		return {
			search: '',
			parent: ''
		}
	},
	props: {
		headers: {
			type: Array as PropType<any[]>,
			required: true
		},
		items: {
			type: Array as PropType<any[]>,
			required: true
		},
		groupBy: {
			type: String,
			required: false
		},
		itemsActions: {
			type: Array as PropType<IGridActions[]>,
			required: false
		},
		itemsPerPage: {
			type: Number,
			required: false
		},
		loading: {
			type: Boolean,
			required: true
		},
		hideFooter: {
			type: Boolean,
			required: false,
			default: false
		},
		actionWidth: {
			type: String,
			required: false,
			default: '100px'
		}
	},
	mounted() {
		this.parent = this.$parent.$options.name;
	},
})
