

import { store } from '@/store';
import { defineComponent, computed } from 'vue';

export default defineComponent({
  name: 'Notify',
  setup() {                  
      return {
          notifications: computed(() => store.state.notifications)
      }
  }
})

