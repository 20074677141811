import Cookie from 'js-cookie';
import api from '@/api/api';
import { store } from '@/store';
import { CURRENT_USER } from '@/store/actions-types';
import { findMenus } from '@/utils/findMenus';
import { POSITION, createToastInterface } from "vue-toastification";

export default {
    async auth(to, from, next) {
        const toast = createToastInterface({
            position: POSITION.TOP_RIGHT,
            timeout: 3000
        })
        try {         						      
            const token = Cookie.get('csnoteweb');
            api.defaults.headers['Authorization'] = `bearer ${token}`;
            const response = await api.get('/auth');                        
            if (response.data.status === 'I') next('/');
            response.data.supervisor = response.data.supervisor === 'S';            
            store.dispatch(CURRENT_USER, response.data);                
            if (!findMenus(response.data.acessos, to.path)){
                next(from.path)
                return toast.error('Acesso não permitido')
            }            
            next();            
        } catch (err) {
            Cookie.remove('csnoteweb');            
            next('/');
        }
    },
    async authed(to, from, next) {
        try {              
            const token = Cookie.get('csnoteweb');
            if(!token) {                
                next();
                return;
            }                        
            api.defaults.headers['Authorization'] = `bearer ${token}`;
            const response = await api.get('/auth');
            if (response.data.status === 'I') Cookie.remove('csnoteweb');
            response.data.supervisor = response.data.supervisor === 'S';            
            store.dispatch(CURRENT_USER, response.data.status);
            next('/home')
        } catch (err) {
            Cookie.remove('csnoteweb');                        
            next('/');
        } 
    }
}