export default function selectOnShift(eventShift, model, dataset, fieldToSearch) {    
    if (eventShift.shiftKey && dataset.length > 0) {
        const startIndex = dataset.findIndex(item => item[fieldToSearch] === model[0]);
        const lastIndex = dataset.findIndex(item => item[fieldToSearch] === model.slice(-1)[0]);
        const itensToSelect = dataset.slice(startIndex, lastIndex);        
        itensToSelect.map((x) => {          
          if (!model.includes(x[fieldToSearch])) {
            model.push(x[fieldToSearch]);
          }
        })
    }
}